
import React, { useState, useEffect } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import Pagination from "../Components/TailwindComponent/Pagination";
import { useLocation } from "react-router-dom";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";

const LoginData = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const location = useLocation();



  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
    setEndDate(finalToday);
    signUpList(finalDate, finalToday, page);
  }, []);

  useEffect(() => {
    if(searchValue === ""){
      signUpList(startDate, endDate);
    }
  }, [page,searchValue === ""]);

  useEffect(() => {
      if(searchValue !== ""){
        searchValueData();
      }
  }, [searchPage]);

  const signUpList = (end, today) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({

      StartDate: end ? end : startDate,
      EndDate: today ? today : endDate,
      page: page,

    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink+"/apiadmin/v1/LoginData",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {

          setData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const searchValueData = (end, today) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchTearm: searchValue,
      page: searchPage,
      // StartDate: end ? end : startDate,
      // EndDate: today ? today : endDate,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink+"/apiadmin/v1/LoginSearchdata", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {

          setData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
        } else {

        }
      })
      .catch((error) => console.log("error", error));
  };
  if (error) return <h1>{error}</h1>;

  const pageHandler = (value) => {
    if(searchValue === ""){
      setPage(value);
    }
    if(searchValue !== ""){
      setSearchPage(value);
    }
  };

  const checkDateSearch = () =>{
    if(page === 1){
      signUpList(startDate, endDate);
    }
  }

  // const checkStringSearch = () =>{
  //   if(searchPage === 1){
  //     searchValueData();
  //   }
  // }

  return (
    <>
      <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
        <DatePicker
          SearchButton={() => {
            setSearchValue("");
            setPage(1);
            checkDateSearch();
          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />
        <Searchbar
          SearchButton={() => {
            setSearchPage(1);
            searchValueData();
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}

        />
      </div>

      {data.length > 0 ? (
        <>
          <List data={data} type={"Login"} />
          {
            <Pagination
              maxPages={maxPage}
              dataCount = {count} 
              datalength={data.length}
              hitPage={pageHandler}
              pageNum={searchValue === "" ? page : searchPage}
            />
          }
        </>
      ) : (
       <DataNotFound/>
      )}
    </>
  );
};
export default LoginData;
