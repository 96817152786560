import React,{useState,useEffect} from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import SignInRegistration from './Components/SignInRegistration';
import DashboardPage from './Screens/DashboardPage'
import DirectoryPage from './Screens/DirectoryPage';
import EnquiryPage from './Screens/EnquiryPage';
import EditScreen from './Screens/EditScreen';
import UserProfile from './Screens/UserProfile';
import UserProfileEdit from './Screens/UserProfileEdit';
import OnePostUserActivity from './Screens/OnePostUserActivity';
import TotalSasOneData from './Screens/TotalSasOneData';
import NewArticles from './Screens/NewArticles';
import ArticleTable from './Screens/ArticleTable';
import UpdateArticle from './Screens/UpdateArticle';
import ScheduleCalender from './Screens/ScheduleCalender';
import AddOccasion from './Screens/AddOccasion';
import OccationDetail from './Screens/OccationDetail';
import OnePOstErrorLog from './Screens/OnePOstErrorLog';
import GMBAccount from './Screens/GMBAccount';
import NewPayment from './Screens/NewPayment';
import BannerList from './Screens/BannerList';
import AddBanner from './Screens/AddBanner';
import Addfaq from './Screens/Addfaq';
import UpdateFaq from './Screens/UpdateFaq';
import CreativeDelivery from './Screens/CreativeDelivery';
import CreativeDeliveryForm from './Screens/CreativeDeliveryForm';
import CustomerFormList from './Screens/CustomerFormList';
import QuestionAnswerList from './Screens/QuestionAnswerList';
import NewQuestionAnswer from './Screens/NewQuestionAnswer';
import UpdateQuestionAnswer from './Screens/UpdateQuestionAnswer';

const App = () => {

  global.apiLink = "https://api.sasone.in";
  global.apiLink8000 = "http://api.sasone.in:8000";
  global.apiLinkPromote8000 = "http://promote.onecorp.co.in:8000";
  global.apiLink8001 = "http://api.sasone.in:8001";

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
      sessionStorage.getItem("email")
          ? setIsUserLoggedIn(true)
          : setIsUserLoggedIn(false);
  }, []);
  return (

    <>
      <Router>
      
        {/* {/ <Header /> /} */}
        <PublicRedirect/>
        <Routes>
          <Route path="/login" element={<SignInRegistration/>} />
          <Route path="/onepost-user-activity" element={<OnePostUserActivity />} />
          <Route path="/schedule-calender" element={<ScheduleCalender />} />
          <Route path="/add-occasion" element={<AddOccasion />} />
          <Route path="/error-log" element={<OnePOstErrorLog  />} />
          <Route path="/add-gmb-account" element={<GMBAccount  />} />


 
          <Route path="/occasion-detail/:OccasionUUID" element={<OccationDetail />} />
          <Route path="/sasonedata" element={<DashboardPage />} />
          <Route path="/directory" element={<DirectoryPage />} />
          <Route path="/enquiry" element={<EnquiryPage />} />
          <Route path="/UserDetailInfo/:id" element = { <EditScreen />} />
          <Route path="userprofile" element = { <UserProfile />} />
          <Route path="/UserProfileEdit/:profileSection/:id" element = { <UserProfileEdit />} />
          <Route path="/newArticle" element={<NewArticles />} />
          <Route path="/articles" element={<ArticleTable />} />
          <Route path="/banner-list" element={<BannerList />} />
          <Route path="/newBanner" element={<AddBanner />} />
          <Route path="/UpdateArticle/:id" element={<UpdateArticle />} />
          <Route path="/UpdateFaq/:id" element={<UpdateFaq />} />
          <Route path="/Addfaq" element={<Addfaq />} />
          <Route path="/creativedelivery" element={<CreativeDelivery />} />
          <Route path="/creativedeliveryform/:id" element={<CreativeDeliveryForm />} />
          <Route path="/customerform" element={<CustomerFormList />} />
          <Route path="/questionanswer" element={<QuestionAnswerList />} />
          <Route path="/newquestion" element={<NewQuestionAnswer />} />
          <Route path="/updatequestion/:id" element={<UpdateQuestionAnswer />} />


          <Route path="/" element={<TotalSasOneData />} />
         

          {/* {/ <TopAlertBanner /> /} */}
        </Routes>
      </Router>

    </>
  )
}
const PublicRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (!localStorage.getItem("UserLoggedIn")) {
      navigate("/login");
     
    }
  }, [pathname]);
  return <></>;
};
export default App;