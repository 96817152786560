import React, { useState,useEffect } from 'react';
import DashboardTab from '../Components/TailwindComponent/DashboardTab';
import Header from '../Components/TailwindComponent/Header';

const DashboardPage = () => {

  const [tabs, setTabs] = useState([])

  useEffect(() => {
    countData()
  }, [])
  

  const tab = [
    { name: "Signup", href: "#", count: `${tabs.totlesignupcount}`, current: true },
    { name: "Login", href: "#", count: `${tabs.totle_logindata}`, current: false },
    { name: "Free Trial", href: "#", count: `${tabs.totle_productdatacount}`, current: false },
    { name: "Subscription", href: "#",count: `${tabs.totle_subcriptionUserdeaticount}`, current: false },
    { name: "Payment Attempt", href: "#",count: `${tabs.totlePaymentCount}`, current: false },
    { name: "Career", href: "#", current: false },
    { name: "Request A Demo", href: "#", current: false },
    { name: "Sales Details", href: "#", current: false },
    { name: "Onboarding", href: "#", current: false },
    { name: "Faq", href: "#", current: false },
    { name: "CustomerFormList", href: "#", current: false },


  ];

  const countData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");


    var raw = "";

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/apiadmin/v1/Countdata", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&&result.status==="Success"){
          setTabs(result)
        }
      })
      .catch(error => console.log('error', error));
  }

  return (
    <>
    <Header/>
      <div className="px-5 sm:px-8">
        <div className="border-b border-gray-200">
          <DashboardTab tabs={tab} />
        </div>
      </div>
    </>
  )
}

export default DashboardPage;