import React from "react";
import Button from "./Button";
import Input from "./Input";

const Searchbar = (props) => {
  
  
  return (
    <div className="flex justify-center pt-4">
        <Input type = "search" value={props.value} onChange={props.onChange} error={props.inputerror} onInput={props.onInput}/>
      <div className="ml-4">
        <Button type = "magnifySearch" onClick={props.SearchButton}/>
      </div>
    </div>
  );
};
export default Searchbar;
