import React, { useState } from "react";
import Header from "../Components/TailwindComponent/Header";
import LoginCount from "./LoginCount";
import PostCount from "./PostCount";
import MostActiveUser from "./MostActiveUser";
import LeastActiveUser from "./LeastActiveUser";
import MostLoginUsers from "./MostLoginUsers";
import LeastLoginUsers from "./LeastLoginUsers";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OnePostUserActivity = (props) => {
  const [currentTab, setCurrentTab] = useState("Login Count");
  const [selectTab, setSelectTab] = useState(0);



  const tabs = [
    { name: "Login Count", href: "#",  current: true },
    { name: "Post Count", href: "#", current: false },
    { name: "Most Active Users", href: "#",  current: false },
    { name: "Least Active Users", href: "#", current: false },
    { name: "Most Login Users", href: "#", current: false },
    { name: "Least Login Users", href: "#", current: false },
  ];
  function changeTab(tab) {
    setCurrentTab(tab);
  }

  const tab = (e) =>{
    switch(e.target.value) {
      case "Login Count":
      {
        setCurrentTab("Login Count");
        setSelectTab(0);
      break;
      }
      case "Post Count":
      {
        setCurrentTab("Post Count");
        setSelectTab(1);
      break;
      }
      case "Most Active Users":
      {
        setCurrentTab("Most Active Users");
        setSelectTab(2);
      break;
      }
      case "Least Active Users":
        {
          setCurrentTab("Least Active Users");
          setSelectTab(3);
        break;
        }
        case "Payment Attempt":
        {
          setCurrentTab("Payment Attempt");
          setSelectTab(4);
        break;
        }
        case "Most Login Users":
        {
          setCurrentTab("Most Login Users");
          setSelectTab(5);
        break;
        }
        case "Least Login Users":
          {
            setCurrentTab("Least Login Users");
            setSelectTab(6);
          break;
          }
    }
  
  }
  
  return (
 <><Header />
    <div className="px-5 mt-4 sm:px-8">
    <div className="sm:hidden sticky top-16 z-0 bg-white">
      <label htmlFor="tabs" className="sr-only">
        Select a tab
      </label>
    
      <select
        id="tabs"
        name="tabs"
        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        defaultValue={tabs.find((tab) => tab.current).name}
        onChange={(e)=>tab(e)}
      >
        {tabs?.map((tab) => (
          <option key={tab.name}>{tab.name}</option>
        ))}
      </select>
    </div>

    <div className="hidden sm:block sticky top-16 z-20 bg-white">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab,index) => (
            <a
              key={tab.name}
              href="#"
              onClick={() => {setSelectTab(index);changeTab(tab.name)}}
              className={classNames(
                selectTab===index
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.name}
      
            </a>
          ))}
        </nav>
      </div>
    </div>
    <div className="mt-4">
    {currentTab==="Login Count"&&  <LoginCount />}
    {currentTab==="Post Count"&& <PostCount />}
  {currentTab==="Most Active Users"&& <MostActiveUser />}
  {currentTab==="Least Active Users"&& <LeastActiveUser />}
  {currentTab==="Most Login Users"&& <MostLoginUsers />}
  {currentTab==="Least Login Users"&& <LeastLoginUsers />}
      </div>

  </div>
 </>
  );
};

export default OnePostUserActivity;
