import React, { useEffect, useState } from 'react'
import LoadingComp from '../Components/TailwindComponent/LoadingComp';
import StatsCard from '../Components/TailwindComponent/StatsCard';

const LoginCount = () => {
    const [loading, setLoading] = useState(false)
    const [loginCount, setLoginCount] = useState([]);
    useEffect(() => {
        LoginCount();
      }, []);
      const LoginCount = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Token 88927950e8858bb239c62d8dcb81292630996841"
        );
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(
          "https://api.sasone.in/apiadmin/v1_1/GetOnepostLoginCountAdmin",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status_code === 200 && result.status === "Success") {
              setLoginCount(result.data);
            }
          })
          .catch((error) => console.log("error", error))
          .finally(()=>setLoading(false))
      };
  return (
    <div className=" border border-slate-100 p-2 sm:p-6 mb-8 rounded">
    <div className='font-bold text-lg'>Login Count</div>
  {loading?<LoadingComp />: <StatsCard data={loginCount} />}  </div>
  )
}

export default LoginCount