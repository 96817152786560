import React from 'react'
import { useNavigate } from 'react-router-dom';
const SpecialDays = (props) => {
    const navigate=useNavigate()
    const dateHandler = (date) => {
      const dateObj = new Date(date);
      const day = dateObj.getDate();
      const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
      const year = dateObj.getFullYear();
      return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
    };
    return (
        <div>
          <div className=" flex flex-col">
            <div className="-my-2  overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle ">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                  
              
                {/* <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  text-center"
                >
                  Sr no.
                </th> */}
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  text-center"
                >
                 Date 
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  text-center"
                >
                Name
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  text-center"
                >
               Add Images 
                </th>
            
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  text-center"
                >
               Add Content 
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  text-center"
                >
            View Detail
                </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    <>
            {props.data?.map((person,dataIndex) => (
              <tr className="divide-x divide-gray-200">
                {/* <td className="whitespace-nowrap text-center py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                  {dataIndex +1}
                </td> */}
                <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                  {dateHandler(person.OccasionDate)}
                </td>
                <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                  {person.OccasionName}
                </td>
                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                  <div
                    className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                    onClick={()=>props.setOpenModal(person.OccasionUUID)}
                  >
                    {" "}
                    Add Image
                  </div>
                </td>
                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                  <div
                    className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                    onClick={()=>props.setModalContent(person.OccasionUUID)}
                  >
                    {" "}
                    Add Content
                  </div>
                </td>
                <td className="relative whitespace-nowrap  text-center text-sm font-medium ">
                  <div
                    className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                    onClick={()=>navigate("/occasion-detail/"+person.OccasionUUID)}
                  >
                    {" "}
                   View
                  </div>
                </td>
              </tr>
            ))}
          </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default SpecialDays