import React,{useState} from "react";
// import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

const SignInRegistration = () => {

  const [showMassage,setShowMassage] = useState("")
  let navigate = useNavigate();

  const onSuccess = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "IdToken": res.credential
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/apiadmin/v1/IdToken", requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status_code === 200 && result.status === "Success"&& result.message==='Data Found Successfully') {
          sessionStorage.setItem("email", result.data.email)
          localStorage.setItem('UserLoggedIn', true)
          localStorage.setItem("imageUrl", result.data.imageUrl)
          
          localStorage.setItem("email", result.data.email)
          sessionStorage.setItem('UserLoggedIn', true)
          navigate("/");
       

        } else {
          setShowMassage("Please login with valid email !!")

        }
      })
      .catch(error => console.log('error', error));
  };
  // const onSuccess = (response) => {
  //   console.log(response);
  // }
  // const onSuccess=(res)=>{
  //   console.log(res);
  // }
  const onFailure = (res) => {
    console.log("error", res);
    // navigate("/");
  };

  // gapi.load("client:auth2", () => {
  //   gapi.client.init({
  //     clientId:
  //       "766882165358-fhbgva1cp4joev9a1vb0pc4uggco8abu.apps.googleusercontent.com",
  //     // "692482674317-ndm6gtt3tcos1s5e2crghmb1jguccdui.apps.googleusercontent.com",
  //     discoveryDocs: ['https://people.googleapis.com/$discovery/rest?version=v1'],
  //     plugin_name: "chat",
  //   });
  // });
  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">

          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-28 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
            </form>
           

            <div className="mt-3">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div>

                {/* <GoogleLogin
                  // clientId={"692482674317-ndm6gtt3tcos1s5e2crghmb1jguccdui.apps.googleusercontent.com"}

                  //live client id //
                  clientId={"766882165358-fhbgva1cp4joev9a1vb0pc4uggco8abu.apps.googleusercontent.com"}
                  //live client id //
                  buttonText="Login"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                  render={(renderProps) => (
                    <div className="m-auto mt-6 flex justify-center items-center bg-blue-500/100 py-1 w-48 rounded hover:bg-blue-400/100 cursor-pointer" onClick={renderProps.onClick}
                      disabled={renderProps.disabled}>
                      <div className="bg-white p-1 rounded" > <FcGoogle size="23" /></div>
                      <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="px-1 text-base font-medium text-white"
                      >
                        Sign in with Google
                      </button>
                    </div>
                  )}
                /> */}
                <div className="mt-4 flex justify-center">
                <GoogleLogin onSuccess={onSuccess} onError={errorMessage} theme="filled_blue" type="standard" />
                </div>
              </div>
              <div className="text-red-500 font-semibold pt-4">{showMassage}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignInRegistration;
