import React, { useState, useEffect } from "react";
import Header from "../Components/TailwindComponent/Header";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import Pagination from "../Components/TailwindComponent/Pagination";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import ExcelDownload from "../Components/ExcelDownload";
import List from "../Components/TailwindComponent/List";
import { useNavigate } from "react-router-dom";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import LoadingComp from "../Components/TailwindComponent/LoadingComp";
import Modal from "../Components/TailwindComponent/Modal";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";

const CreativeDelivery = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [maxPage, setMaxPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [count, setCount] = useState("");
  const [deliverData, setDeliverData] = useState([]);
  const [maxPageDeliver, setMaxPageDeliver] = useState(1);
  const [countDeliver, setCountDeliver] = useState("");
  const [searchValueDeliver, setSearchValueDeliver] = useState("");
  const [searchPageDeliver, setSearchPageDeliver] = useState(1);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [articleUUID, setArticleUUID] = useState("");
  const [tab, setTab] = useState("Creative Request");

  const tabs = [
    {
      name: "Creative Request",
      current: true,
    },
    {
      name: "Creative Delievered",
      current: false,
    },
  ];
  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateFormat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateFormat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();

    const todayMonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
    setEndDate(finalToday);
  }, []);

  useEffect(() => {
    fetchArticleListFunc();
    DelivereyRequestlist();
  }, [searchPage, startDate, endDate, searchValue,searchPageDeliver,searchValueDeliver]);
 

  // const data =[
  //     {
  //         Email:"shivam@onecorp.co.in",
  //         Request:"Craetive",
  //         Images:"https://www.sasone.in/SASLOGO2.svg",
  //         deliverydate:"2023-04-25T19:03:38",
  //         description:"testing for creative delivery"
  //     }
  // ]

  const fetchArticleListFunc = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );

    var formdata = new FormData();
    formdata.append("page", searchPage);
    formdata.append("StartDate", startDate);
    formdata.append("EndDate", endDate);
    formdata.append("Search", searchValue);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/CreativeRequestedList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const DelivereyRequestlist = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );

    var formdata = new FormData();
    formdata.append("page", searchPageDeliver);
    formdata.append("StartDate", startDate);
    formdata.append("EndDate", endDate);
    formdata.append("Search", searchValueDeliver);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/DeliveredCreativeList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code == 200 && result.status === "Success") {
          setDeliverData(result.data);
          setCountDeliver(result.count);
          setMaxPageDeliver(Math.ceil(result.count / 20));
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageHandler = (value) => {
    setSearchPage(value);
  };
  const pageHandlerTwo = (value) => {
    setSearchPageDeliver(value);
  };

  const messageCloseFunc = () => {
    setTimeout(() => {
      setMessageOpen(false);
    }, 2000);
  };

 

  return (
    <>
      <Header />
      <div className="px-5 sm:px-8">
        <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
          <DatePicker
            SearchButton={() => {
              setSearchValue("");
              setSearchValueDeliver("");
              setSearchPage(1);
              fetchArticleListFunc();
            }}
            StartValue={startDate}
            EndValue={endDate}
            StartonChange={(e) => setStartDate(e.target.value)}
            EndonChange={(e) => setEndDate(e.target.value)}
          />
          <Searchbar
            SearchButton={() => {
              setSearchPage(1);
              setSearchPageDeliver(1);
              fetchArticleListFunc();
              DelivereyRequestlist();
            }}
            value={searchValue?searchValue:searchValueDeliver}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setSearchValueDeliver(e.target.value);
            }}
            onClick={() => alert("Hello")}
          />
        </div>
        <ul
          class="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 "
          id="tabs-tabFill"
          role="tablist"
        >
          <li class="nav-item flex flex-auto text-center" role="presentation">
            {tabs.map((item) => (
              <a
                key={item.name}
                href={item.href}
                onClick={() => setTab(item.name)}
                className={`cursor-pointer nav-link w-full block font-bold text-xs leading-tight 
              uppercase border-x-0 border-t-0 border-b-2 border-transparent 
              px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 
              focus:border-transparent active 
                              ${
                                item.name === tab
                                  ? "border-pink-500 text-gray-900"
                                  : "border-gray-200 text-gray-900"
                              } `}
                aria-current={item.name ? "page" : undefined}
              >
                {item.name}
              </a>
            ))}
          </li>
        </ul>

        {tab === "Creative Request" && (
          <div>
            <div className="border-b border-gray-200">
              <div className="">
                {data.length > 0 ? (
                  <>
                    <div className="flex items-center justify-end">
                      <ExcelDownload
                        csvData={data}
                        fileName="Creative Request List"
                        Excel={"Download Excel"}
                      />
                    </div>
                    {loading ? (
                      <LoadingComp />
                    ) : (
                      <>
                        <List
                          data={data}
                          type={"CreativeRequest"}
                          pageNum={searchPage}
                          setArticleUUID={setArticleUUID}
                          setOpen={setOpen}
                          messageCloseFunc={messageCloseFunc}
                          setAlertMessage={setAlertMessage}
                          setMessageOpen={setMessageOpen}
                        />
                        {
                          <Pagination
                            maxPages={maxPage}
                            dataCount={count}
                            datalength={data.length}
                            hitPage={pageHandler}
                            pageNum={searchPage}
                          />
                        }
                      </>
                    )}
                  </>
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>
          </div>
        )}
        {tab === "Creative Delievered" && (
          <div>
            <div className="border-b border-gray-200">
              <div className="mt-2">
                {deliverData.length > 0 ? (
                  <>
                    <div className="flex items-center justify-end">
                      <ExcelDownload
                        csvData={deliverData}
                        fileName="Creative Delivery List"
                        Excel={"Download Excel"}
                      />
                    </div>
                    {loading ? (
                      <LoadingComp />
                    ) : (
                      <>
                        <List
                          data={deliverData}
                          type={"CreativeDelivery"}
                          pageNum={searchPageDeliver}
                          setArticleUUID={setArticleUUID}
                          setOpen={setOpen}
                          messageCloseFunc={messageCloseFunc}
                          setAlertMessage={setAlertMessage}
                          setMessageOpen={setMessageOpen}
                        />
                        {
                          <Pagination
                            maxPages={maxPageDeliver}
                            dataCount={countDeliver}
                            datalength={deliverData.length}
                            hitPage={pageHandlerTwo}
                            pageNum={searchPageDeliver}
                          />
                        }
                      </>
                    )}
                  </>
                ) : (
                  <DataNotFound />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreativeDelivery;
