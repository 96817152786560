import { useState, useEffect } from "react";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import Header from "../Components/TailwindComponent/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/TailwindComponent/Button";
import { FaRegEdit } from "react-icons/fa"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EditScreen = () => {
  const location = useLocation();
  const currentTab = location.state;
  const [tab, setTab] = useState(currentTab?.currentTab);
  const [userProfile, setUserProfile] = useState({});
  const [subscriptionData, setSubcriptionData] = useState({});
  const [paymentData, setPaymentData] = useState({});


  const navigate = useNavigate();
  const tabs = [
    { name: "Profile", current: true },
    { name: "Subscription", current: false },
    { name: "Payment Attempt", current: false },
  ];
  const profile = {
    userInfo: {
      "Full Name": (userProfile?.FirstName || userProfile?.LastName) ? userProfile?.FirstName + " " + userProfile?.LastName : <></>,
      Phone: userProfile?.Mobile ? userProfile?.Mobile : "(555) 123-4567",
      Email: userProfile?.Email
        ? userProfile?.Email
        : <></>,
      "Job title": userProfile?.JobTitle
        ? userProfile?.JobTitle
        : "Senior Front-End Developer",

      Location: userProfile?.Address ? userProfile?.Address + "," + userProfile?.State + "," + userProfile?.Country + "," + userProfile?.PinCode : <></>,
      Username: userProfile?.UserName ? userProfile?.UserName : <></>,
      Source: userProfile?.Source,
      Device: userProfile?.device,
      "Promo code":userProfile?.promocode
    },
    companyInfo: {
      "Company Name": userProfile?.CompanyName ? userProfile?.CompanyName : <></>,
      "Company Email": userProfile?.CompanyEmail ? userProfile?.CompanyEmail : <></>,
      "Company Phone": userProfile?.CompanyPhone ? userProfile?.CompanyPhone : <></>,
      "Company Name": userProfile?.CompanyName ? userProfile?.CompanyName : <></>,
      "Company Website": userProfile?.CompanyWebsite ? userProfile?.CompanyWebsite : <></>,
      "Number of Employees": userProfile?.NumberOfEmployee ? userProfile?.NumberOfEmployee : <></>,
      "Company Location": userProfile?.CompanyAddress ? userProfile?.CompanyAddress + "," + userProfile?.CompanyState + "," + userProfile?.CompanyCountry + "," + userProfile.CompanyPincode : <></>,
      "GST Number": userProfile?.GSTIN ? userProfile?.GSTIN : <></>,
      "PAN Number": userProfile?.PAN ? userProfile?.PAN : <></>
    },
  };

  const subscription = {
    "Subscription Type": subscriptionData?.SubscriptionType
      ? subscriptionData.SubscriptionType
      : <></>,
    "Service Type": subscriptionData?.SubscriptionType
      ? subscriptionData.SubscriptionType
      : <></>,
    "Product Name": subscriptionData?.ProductName
      ? subscriptionData.ProductName
      : <></>,
    "Total Amount": subscriptionData?.TotalAmount
      ? subscriptionData.TotalAmount
      : <></>,
    "Promo-code": <></>,
    "Valid From": subscriptionData?.ValidFrom
      ? subscriptionData.ValidFrom
      : <></>,
    "Valid To": subscriptionData?.ValidTo ? subscriptionData.ValidTo : <></>,
  };

  let url = window.location.href;
  let object = new URL(url);
  let path = object.pathname;
  let RequirementID = path.split("/").pop();
  console.log("userid", RequirementID);

  useEffect(() => {
    getProfile();
  }, []);

  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };

  const getProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserId: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/apiadmin/v1/UserFullDeatil", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setUserProfile(result.signupdatauser);
          setSubcriptionData(result.subcriptiondata);
          setPaymentData(result.paymentdata)
          console.log(result.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // const ActiveTab = (type) => {
  //   if (type === "Profile" && currentTab?.currentTab === "Profile") {
  //     return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //   } else if (currentTab?.currentTab === "Subscription") {
  //     if (type === "Subscription" && tab === "Subscription") {
  //       return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     } else if (tab === "Profile" && type === "Profile") {
  //       return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     } else if (type === "Payment Attempt") {
  //       return "opacity-50 pointer-events-none border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     } else {
  //       return "border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     }
  //   }
  //   else if (currentTab?.currentTab === "Payment Attempt") {
  //     if (type === "Payment Attempt" && tab === "Payment Attempt") {
  //       return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     } else if (tab === "Profile" && type === "Profile") {
  //       return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     } else if (type === "Subscription" && tab === "Subscription") {
  //       return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     } else {
  //       return "border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //     }
  //   } else {
  //     return "opacity-50 pointer-events-none border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  //   }
  // };
  const ActiveTab = (type) => {
    if (currentTab.currentTab === "Profile") {
      // return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      if (type === "Subscription" && tab === "Subscription") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else if (tab === "Profile" && type === "Profile") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else if (tab === "Payment Attempt" && type === "Payment Attempt") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else {
        return "border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      }
    }
    else if (currentTab.currentTab === "Subscription") {
      if (type === "Subscription" && tab === "Subscription") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else if (tab === "Profile" && type === "Profile") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else if (type === "Payment Attempt") {
        return "opacity-50 pointer-events-none border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else {
        return "border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      }
    } else if (currentTab?.currentTab === "Payment Attempt") {
      if (type === "Payment Attempt" && tab === "Payment Attempt") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else if (tab === "Profile" && type === "Profile") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else if (type === "Subscription" && tab === "Subscription") {
        return "border-transparent border-pink-500 hover:text-gray-700 hover:border-pink-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      } else {
        return "border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
      }
    }

    else {
      return "opacity-50 pointer-events-none border-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
    }
  };
  return (
    <>
      <Header />
      <div className="flex h-full">
        <div className="min-w-0 flex-1">
          <div className="flex flex-1 overflow-hidden">
            <main className="flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>

                <div>
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="mt-5 sm:mt-5 sm:flex sm:items-end sm:space-x-5">
                      <div className="flex items-center space-between space-x-8 sm:items-start">
                        {userProfile?.ProfilePicture ? (
                          <img
                            className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                            src={userProfile?.ProfilePicture}
                            alt=""
                          />
                        ) : (
                          <div className="h-32 w-32 bg-gray-100 rounded-full flex justify-center items-center text-2xl font-semibold">
                            {userProfile?.FirstName?.charAt(0).toUpperCase() +
                              " " +
                              userProfile?.LastName?.charAt(0).toUpperCase()}
                          </div>
                        )}
                        <div className="space-y-2 sm:space-y-2 sm:pt-10">
                          <h1 className="truncate text-2xl font-bold text-gray-900">
                            {userProfile?.FirstName +
                              " " +
                              userProfile?.LastName}
                          </h1>

                          <p className="max-w-2xl text-sm text-gray-500">
                            {userProfile?.JobTitle
                              ? userProfile.JobTitle
                              : <></>}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                      <nav
                        className="-mb-px flex space-x-8 cursor-pointer"
                        aria-label="Tabs"
                      >
                        {tabs.map((item) => (
                          <div
                            key={item.name}
                            href={item.href}
                            onClick={() => setTab(item.name)}
                            className={
                              ActiveTab(item.name)
                              // item.name === "Profile" ? "border-pink-500 text-gray-900":"",
                              //  item.name === currentTab.currentTab
                              //     ? item.name === tab ? "border-pink-500":"text-gray-900"

                              //     : item.name !== "Profile"? "opacity-50 pointer-events-none border-none" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              //   "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            }
                            aria-current={item.name ? "page" : undefined}
                          >
                            {item.name}
                          </div>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>


                {tab === "Profile" && (
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="rounded border-4 border-gray-100 p-5">
                      <div className="space-y-3 py-4 pt-0 sm:space-y-5 sm:pt-0">
                        <div className="flex justify-between">
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Personal Information
                          </h3>
                          <div onClick={() => navigate("/UserProfileEdit/" + "personalInfo/" + userProfile.UserId)} className="cursor-pointer inline-flex items-center justify-center p-2 bg-indigo-500 hover:bg-indigo-300 rounded-md shadow-lg"><FaRegEdit size={18} color={"White"} /></div>
                        </div>
                      </div>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2">
                        {Object.keys(profile.userInfo).map((field) => (
                          <div key={field} className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              {field}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {profile.userInfo[field]}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    </div>
                    <div className="mt-4 rounded border-4 border-gray-100 p-5">
                      <div className="space-y-4 py-4 pt-0 sm:space-y-5">
                        <div className="flex justify-between">
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Company Information
                          </h3>
                          <div onClick={() => navigate("/UserProfileEdit/" + "companyInfo/" + userProfile.UserId)} className="cursor-pointer inline-flex items-center justify-center p-2 bg-indigo-500 hover:bg-indigo-300 rounded-md shadow-lg"><FaRegEdit size={18} color={"White"} /></div>
                        </div>
                      </div>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2">
                        {Object.keys(profile.companyInfo).map((field) => (
                          <div key={field} className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              {field}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {profile.companyInfo[field]}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    </div>
                    {userProfile?.Notes &&
                      <dl className="rounded border-4 border-gray-100 p-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-3 mb-3 p-4 rounded">
                        <div className="sm:col-span-1">
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Notes
                          </h3>
                          <dd className="mt-1 text-sm text-gray-900">
                            {userProfile.Notes && userProfile.Notes}
                          </dd>
                        </div>
                      </dl>}
                  </div>
                )}
                {tab === "Subscription" && (
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="">
                      {(subscriptionData!==null&&subscriptionData.length) ? subscriptionData?.map((item) => {
                        return (
                          <dl className="rounded border-4 border-gray-100 p-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 mb-3 p-4 rounded">

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Product Name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.ProductName}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Total Amount
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.TotalAmount}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Subscription Type
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.SubscriptionType}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Service Type
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.ServiceType}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Valid From
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.ValidFrom && dateHandler(item.ValidFrom)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Valid To
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.ValidTo && dateHandler(item.ValidTo)}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Promo code
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.promocode}
                              </dd>
                            </div>
                          </dl>
                        )
                      }) : <DataNotFound />}
                    </div>
                  </div>
                )}
                {tab === "Payment Attempt" && (
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="">
                      {(paymentData !== null && paymentData.length) ? paymentData?.map((item) => {
                        return (
                          <dl className="rounded border-4 border-gray-100 p-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 mb-3 p-4 rounded">

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Payment Status
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.PaymentStatus}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                GST
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.GST}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Amount
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.PGAmount}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Payment Method
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.PGMethod}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Discount
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.Discount}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Updated At
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {item.UpdatedAt && dateHandler(item.UpdatedAt)}
                              </dd>
                            </div>

                          </dl>
                        )
                      }) : <DataNotFound />}
                    </div>
                  </div>
                )}
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditScreen;
