import React, { useState, useEffect } from 'react'
import DataNotFound from '../Components/TailwindComponent/DataNotFound'
import List from '../Components/TailwindComponent/List'
import Pagination from '../Components/TailwindComponent/Pagination';
import ExcelDownload from "../Components/ExcelDownload";
import DatePicker from '../Components/TailwindComponent/DatePicker';
import Searchbar from '../Components/TailwindComponent/Searchbar';

const RequestADemo = () => {

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [maxPage, setMaxPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [count, setCount] = useState("");


    useEffect(() => {
        var date = new Date();
        date.setDate(date.getDate() - 30);

        let startDateFormat =
            date.getDate().toString().length === 1
                ? "0" + date.getDate()
                : date.getDate();

        const monthFormat =
            date.getMonth() + 1 > 9
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1);

        var finalDate =
            date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

        setStartDate(finalDate);

        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate());

        let endDateFormat =
            todayDate.getDate().toString().length === 1
                ? "0" + todayDate.getDate()
                : todayDate.getDate();

        const todayMonthFormat =
            todayDate.getMonth() + 1 > 9
                ? todayDate.getMonth() + 1
                : "0" + (todayDate.getMonth() + 1);

        var finalToday =
            todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
        setEndDate(finalToday);
    }, []);

    useEffect(() => {
        RequestADemo()
    }, [searchPage, startDate, endDate])

    const RequestADemo = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("page", searchPage);
        formdata.append("SearchTerm", searchValue);
        formdata.append("StartDate", startDate);
        formdata.append("EndDate", endDate);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/RequestADemoList", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code == 200 && result.status === "Success") {
                    setData(result.data);
                    setCount(result.count);
                    setMaxPage(Math.ceil(result.count / 20));
                }
            })
            .catch(error => console.log('error', error));
    }

    const pageHandler = (value) => {
        setSearchPage(value);
    };

    return (
        <>
            <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
                <DatePicker
                    SearchButton={() => {
                        setSearchValue("");
                        setSearchPage(1);
                        RequestADemo();
                    }}
                    StartValue={startDate}
                    EndValue={endDate}
                    StartonChange={(e) => setStartDate(e.target.value)}
                    EndonChange={(e) => setEndDate(e.target.value)}
                />
                <Searchbar
                    SearchButton={() => {
                        setSearchPage(1);
                        RequestADemo();
                    }}
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}

                />
            </div>
            {data.length > 0 ? (
                <>
                    <ExcelDownload csvData={data} fileName="Request A Demo List" Excel={"Request Demo Excel Download"} />
                    <List data={data} type={"Requestademo"} />
                    {
                        <Pagination
                            maxPages={maxPage}
                            dataCount={count}
                            datalength={data.length}
                            hitPage={pageHandler}
                            pageNum={searchPage}
                        />
                    }
                </>
            ) : (
                <DataNotFound />
            )}
        </>
    )
}

export default RequestADemo