import { TailSpin } from "react-loader-spinner";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
const Button = (props) => {

  const buttonType = (type) => {
    switch (type) {
      case "smallButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="bg-indigo-500 border border-indigo-500 rounded hover:bg-white hover:border-indigo-500 hover:text-indigo-500 text-white font-semibold py-1.5 px-4 rounded "
            >
                 {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "magnifySearch":
        return (
            <button
            type="submit"
            onClick={props.onClick}
            className="inline-flex justify-center rounded-full border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          >
            <MagnifyingGlassIcon
              className="h-6 w-4 text-gray-400"
              aria-hidden="true"
            />
            <span className="sr-only">Search</span>
          </button> 
        );
      case "greenLong":
        return (
          <button
            type="button"
            onClick={props.onClick}
            className="inline-flex items-center rounded border border-transparent bg-greenButton px-6 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-lightGreen focus:outline-none "
          >
                  {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
          </button>
        );
      case "redFilled":
        return (
          <>
            <button
              onClick={props.onClick}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-redHeader px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none "
            >
              {props.loading ? (
                <TailSpin height="20" width="20" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      case "whiteButton":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-gray bg-white px-5 py-2 text-sm font-medium leading-4 text-gray shadow-sm hover:bg-Gray focus:outline-none "
            >
              {props.buttonText}
            </button>
          </>
        );
      case "greenFilled":
        return (
          <>
            <button
              type="button"
              onClick={props.onClick}
              className="inline-flex items-center rounded-md border border-transparent bg-greenButton px-5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-lightGreen focus:outline-none"
            >
                    {props.loading ? (
                <TailSpin height="18 " width="18" color="white" />
              ) : (
                props.buttonText
              )}
            </button>
          </>
        );
      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{buttonType(props.type)}</div>;
};

export default Button;
