import React from 'react'

const StatsCard = (props) => {
    const YTD = [
        {
          name: `Today`,
          Count:  props.data.CurrentDateLoginCount||0,
        },
        {
          name: `This Week`,
          Count:  props.data.CurrentWeekLoginCount||0,
        },
        {
          name: " This Month",
          Count:  props.data.CurrentMonthLoginCount||0,
        },
      ];
  return (
    
      <div>
        <dl className="mt-5 mb-5 grid grid-cols-1 gap-3 xl:grid-cols-3 lg:grid-cols-3  md:grid-cols-2 sm:grid-cols-1">
          {YTD?.map((item, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-lg bg-white px-1 pt-1 pb-1 shadow sm:w-96"
            >
              <dt>
                <p className=" ml-2 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className=" ml-2 flex items-baseline ">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.Count}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
  )
}

export default StatsCard