import React, { useState, useEffect } from "react";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import Searchbar from "../Components/TailwindComponent/Searchbar";
import Pagination from "../Components/TailwindComponent/Pagination";
import { useLocation } from "react-router-dom";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import Modal from "../Components/TailwindComponent/Modal";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";

const SalesExecutive = (props) => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [maxPage, setMaxPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [count, setCount] = useState("");
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [userID, setUserID] = useState("")
  const [email, setEmail] = useState("")
  const [show, setShow] = useState(false);
  const [apiFailled, setApiFailled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Status Updated");

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateFormat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateFormat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();

    const todayMonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
    setEndDate(finalToday);
  }, []);

  useEffect(() => {
    SalesDetailsList();
  }, [searchPage, startDate, endDate,searchValue]);


  // useEffect(() => {
  //   searchValueData();
  // }, [searchPage, startDate, endDate]);

  const SalesDetailsList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );

    var formdata = new FormData();
    formdata.append("page", searchPage);
    formdata.append("SearchTerm", searchValue);
    formdata.append("StartDate", startDate);
    formdata.append("EndDate", endDate);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/SmUsersListmanual",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        if (result.status_code === 200 && result.status === "Success") {

          setData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // const searchValueData = (end, today) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     "Token 88927950e8858bb239c62d8dcb81292630996841"
  //   );
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     SearchTearm: searchValue,
  //     page: searchPage,
  //     // StartDate: end ? end : startDate,
  //     // EndDate: today ? today : endDate,

  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch("https://api.sasone.in/apiadmin/v1/SmUsersList", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status_code === 200 && result.status === "Success") {

  //         setData(result.data);
  //         setCount(result.count);
  //         setMaxPage(Math.ceil(result.count / 20));
  //       } else {

  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  //   const searchValueData = (end, today) => {
  //     var myHeaders = new Headers();
  //     myHeaders.append(
  //       "Authorization",
  //       "Token 88927950e8858bb239c62d8dcb81292630996841"
  //     );
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       SearchTearm: searchValue,
  //       page: searchPage,
  //       StartDate: end ? end : startDate,
  //       EndDate: today ? today : endDate,
  //     });

  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch(global.apiLink+"/apiadmin/v1/SignupDataSearch", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.status_code === 200 && result.status === "Success") {

  //           setData(result.data);
  //           setCount(result.count);
  //           setMaxPage(Math.ceil(result.count / 20));
  //         } else {

  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   };
  if (error) return <h1>{error}</h1>;

  //   const pageHandler = (value) => {
  //     if(searchValue === ""){
  //       setPage(value);
  //     }
  //     if(searchValue !== ""){
  //       setSearchPage(value);
  //     }
  //   };

  //   const checkDateSearch = () =>{
  //     if(page === 1){
  //       signUpList(startDate, endDate);
  //     }
  //   }

  //   const checkStringSearch = () =>{
  //     if(searchPage === 1){
  //       searchValueData();
  //     }
  //   }

  const OpenModal = (id) => {
    setOpenModal(true)
    setUserID(id)
  }

  const UpdateSalesExecutive = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "UserId": userID,
      "SalesExecutive": email
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/v1/SalesExecutiveUpdate", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "Success" && result.message === "Data Updated Successfully") {
          setOpenModal(false)
          setAlertMessage("Update Successfully!");
          setApiFailled(false);
          setShow(true);
          SalesDetailsList()
        }
        else {
          setOpenModal(false)
          setAlertMessage("Status Failed!");
          setApiFailled(true);
          setShow(true);
        }
      })
      .catch(error => console.log('error', error));
  }

  const pageHandler = (value) => {
    setSearchPage(value);
};

  return (
    <>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        onClose={() => {
          setOpenModal(false);
          setUserID("")
        }}
        type={"UpdateSales"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onSubmit={UpdateSalesExecutive}
      // minValid={changeDate}
      />
      <SuccessMessage
        show={show}
        apiFailled={apiFailled}
        message={alertMessage}
        onClose={() => {
          setShow(false);
        }}
      />
      <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
        <DatePicker
          SearchButton={() => {
            setPage(1);
            SalesDetailsList();
          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />
        <Searchbar
          SearchButton={() => {
            setSearchPage(1);
            SalesDetailsList();
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}

        />
      </div>

      {data.length > 0 ? (
        <>
          <List data={data} type={"Sales"} modalOpen={(i) => OpenModal(i)} />
          {
            <Pagination
              maxPages={maxPage}
              dataCount={count}
              datalength={data.length}
              hitPage={pageHandler}
              pageNum={searchPage}
            />
          }
        </>
      ) : (
        <DataNotFound />
      )}
    </>
  );
};
export default SalesExecutive;
