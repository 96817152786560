import React, { useEffect, useState } from "react";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import ErrorLogGrid from "../Components/TailwindComponent/ErrorLogGrid";
import Header from "../Components/TailwindComponent/Header";
import LoadingComp from "../Components/TailwindComponent/LoadingComp";
import Pagination from "../Components/TailwindComponent/Pagination";


const OnePOstErrorLog = () => {
  const [data, setData] = useState([]);
  const [loading,setLoading]=useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState("");

useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateformat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateformat;
    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateformat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();
    const todaymonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todaymonthFormat + "-" + endDateformat;
      setEndDate(finalToday)
ErrorList(finalDate,finalToday,page)
}, [page])


const ErrorList=(startdate,enddate,page)=>{
  setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "EndDate": enddate,
      "StartDate":startdate,
      "page": page,
      "content":20
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://api.sasone.in/apiadmin/v1_1/GetErrorLogAdminBylist", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&& result.status==="Success"){
            setData(result.data)
            setCount(result.count)
        }
      })
      .catch(error => console.log('error', error)).finally(()=>setLoading(false))
}
const pageHandler = (value) => {
  setPage(value);
};

  return (
    <div>
      <Header />

      <div className="  p-2 sm:p-6 mb-8 rounded">
      <div className="font-bold text-lg">Error Log</div>{" "}
        <div className={"flex justify-between items-center mb-5"}>
          {" "}
          <DatePicker
          SearchButton={() => {
            ErrorList(startDate,endDate,page);
            // setPage(1)
          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />
      
        </div>
  {loading ? (
        <LoadingComp />
      ) : <> { data.length? <><ErrorLogGrid
          data={data}
        />   <Pagination
        maxPages={Math.ceil(count / 20)}
        dataCount={count}
        hitPage={pageHandler}
        datalength={data?.length}
        pageNum={ page }
      />  </>
        
        :<DataNotFound/>}</>  }
      </div>
    </div>
  );
};

export default OnePOstErrorLog;
