import React from "react";
const profile = {
  name: "Ricardo Cooper",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  coverImageUrl:
    "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  about: `
    <p>Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.</p>
    <p>Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus praesent dictum risus suspendisse.</p>
  `,
  fields: {
    Phone: "(555) 123-4567",
    Email: "mailto:ricardocooper@example.com",
    Title: "Senior Front-End Developer",
    Team: "Product Development",
    Location: "San Francisco",
    Sits: "Oasis, 4th floor",
    Salary: "$145,000",
    Birthday: "June 8, 1990",
  },
};

const UserProfile = () => {
  return (
    <>
      <div className="flex h-full">
        <div className="min-w-0 flex-1">
          <div className="flex flex-1 overflow-hidden">
            <main className="flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                <div className="">
                  <div>
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                      <div className="my-5 sm:mt-5 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex items-center space-between space-x-8 sm:items-start">
                          <div class="rounded">
                            <img
                              class="h-24 w-24 object rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmotdpOehtJ5noM9AVfxs7O7xDyaKr6rUr24tzbscIkDYiyLOE1y7Kz4T5Sny0n3Kpx_k&usqp=CAU"
                            />
                          </div>
                          <div className="space-y-2 sm:space-y-2 sm:pt-10">
                            <h1 className="truncate text-2xl font-bold text-gray-900">
                              Shivam Verma
                            </h1>

                            <p className="max-w-2xl text-sm text-gray-500">
                              Sr. IT Team Leader
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="rounded border-4 border-gray-100 p-5">
                      <div className="space-y-6 py-6 pt-0 sm:space-y-5 sm:pt-0">
                        <div>
                          <h3 className="text-gray-900 font-bold text-2xl">
                            Personal Information
                          </h3>
                        </div>
                      </div>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        {Object.keys(profile.fields).map((field) => (
                          <div key={field} className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              {field}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {profile.fields[field]}
                            </dd>
                          </div>
                        ))}
                      </dl>
                    </div>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProfile;
