import DataNotFound from "./DataNotFound";

const OccasionDetailGrid = (props) => {
    const dateHandler = (date) => {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
        const year = dateObj.getFullYear();
        return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
      };
  return (
    <div className="">
      <main className="mx-auto max-w-2xl pt-8 pb-24 sm:px-6  lg:max-w-7xl lg:px-8">
   <section aria-labelledby="products-heading" className="">
          <h2 id="products-heading" className="text-lg font-bold">
          {props.type==="images"?"Images":"Content"}
          </h2>
          <div className=" pb-4 text-sm text-gray-600 "> {props.occasion} | {dateHandler(props.occasionDate)}</div>
          <div className="grid grid-cols-3 gap-4">
            {props.data?.map((product) => (
              <div
                key={product.id}
                className="border-t border-b border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
              >
                <div className="py-6 px-4 sm:px-6 ">
                  <div className="">
                  {props.type==="images"? 
                   <div className="aspect-w-1 aspect-h-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none ">
                      <img
                        src={product.CreativeLink}
                        alt={product.OccasionName}
                        className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                      />
                    </div>:    <div className="">
                      <p className=" text-sm text-gray-500">{product.TextDescription}</p>
                    </div>}
                  </div>
                </div>

             
              </div>
            ))}
          </div>
        </section>
      

      </main>

  
    </div>
  )
}
export default OccasionDetailGrid
