import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Badges from "../Components/TailwindComponent/Badges";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import Header from "../Components/TailwindComponent/Header";
import Input from "../Components/TailwindComponent/Input";
import LoadingComp from "../Components/TailwindComponent/LoadingComp";
import Modal from "../Components/TailwindComponent/Modal";
import Pagination from "../Components/TailwindComponent/Pagination";
import SpecialDays from "../Components/TailwindComponent/SpecialDays";
import SuccessMessage from "../Components/TailwindComponent/SuccessMessage";

const ScheduleCalender = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [modalContent, setModalContent] = useState(false);
  const [selectedImages, setSelectedImages] = useState("");
  const [error, setError] = useState({});
  const [loading,setLoading]=useState(false);
  const [isImage,setIsImage]=useState(false);
  const [isContent,setIsContent]=useState(false);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState("");
  const [apiFailled, setApiFailled] = useState(false);
  const [message, setMessage] = useState("");
  const formValidationContent = () => {
    let errors = {};
    let isValid = true;

    if (!title || title === "") {
      errors.title = "Please enter title";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };
  const formValidation = () => {
    let errors = {};
    let isValid = true;

    if (selectedImages.length < 1) {
      errors.selectedImages = "Please select your picture";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };
useEffect(() => {
occasionList()
}, [page])

const occasionList=(startdate,enddate)=>{
  setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "EndDate": enddate,
      "StartDate":startdate,
      "page": page,
      "content":20
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch("https://api.sasone.in/apiadmin/v1_1/GetOccasionDataBylist", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&& result.status==="Success"){
            setData(result.data)
            setCount(result.count)
        }
      })
      .catch(error => console.log('error', error)).finally(()=>setLoading(false))
}
const InsertContent=()=>{
  setIsContent(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "OccasionUUID": modalContent,
      "TextDescription": title
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://api.sasone.in/apiadmin/v1_1/InsertOccasionCalendarText", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&& result.status==="Success"){
        setTitle("")
        setModalContent("")
            setApiFailled(false);
            setMessage("Content Added Successfully.");
          }  else {
            setApiFailled(true);
            setMessage("Something went wrong please contact support.");
          }
      })
      .catch(error => console.log('error', error)).finally(()=>setIsContent(false))
  }
  const InsertImage=()=>{
    setIsImage(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    
    var formdata = new FormData();
    formdata.append("OccasionUUID", openModal);
    formdata.append("CreativeLink", selectedImages);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://api.sasone.in/apiadmin/v1_1/InsertOccasionCalendarCreatives", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&& result.status==="Success"){
        setSelectedImages("")
        setOpenModal("")
            setApiFailled(false);
            setMessage("Image Added Successfully.");
          }  else {
            setApiFailled(true);
            setMessage("Something went wrong please contact support.").finally(()=>setIsImage(false))
          }
      })
      .catch(error => console.log('error', error));
  }
  const pageHandler = (value) => {
    setPage(value);
  };
  const successMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 4000);
    return message;
  };
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      {message && (
        <SuccessMessage
        show={message?true:false}
          onClose={() =>
           setMessage("")
          }
          apiFailled={apiFailled}
          message={successMessage()}
        />
     )} 
      <Modal
        open={openModal?true:false}
        setOpen={setOpenModal}
        heading={"Add Image"}
        loading={isImage?true:false}
        onSubmit={(e) => {
          e.preventDefault();
          formValidation()?InsertImage():console.log("Something went wrong.");
        }}
        onClose={() => {
          setOpenModal(false);
        }}
        type={"scheduleOccassion"}
      >
        <Input
          type="files"
          label="Upload Images "
          onChange={(e) => setSelectedImages(e.target.files[0])}
          value={selectedImages}
          error={error.selectedImages}
          onInput={() => (error.selectedImages = "")}
        />

        <div className="flex flex-row space-x-2">
          {selectedImages ? (
            <Badges
              item={selectedImages}
              createarticle={"createarticle"}
              onClick={() => setSelectedImages("")}
            />
          ) : (
            <></>
          )}
        </div>
      </Modal>
      <Modal
        open={modalContent?true:false}
        setOpen={setModalContent}
        heading={"Add Content"}
        loading={isContent?true:false}
        onSubmit={(e) => {
          e.preventDefault();
          formValidationContent()?InsertContent():console.log("Something went wrong.");
        }}
        onClose={() => {
          setModalContent(false);
        }}
        // onClick={()=>formValidation()}
        type={"scheduleOccassion"}
      >
        <Input
          label="Content"
          type="textarea"
          value={title}
          inputType={"text"}
          placeholder="Enter Content "
          error={error.title}
          // onInput={() => (error.title = "")}
          onChange={(e) => {
            setTitle(e.target.value);
            error.title = "";
          }}
        />
      </Modal>
      <div className="  p-2 sm:p-6 mb-8 rounded">
      <div className="font-bold text-lg">Schedule Calender</div>{" "}
        <div className={"flex justify-between items-center mb-5"}>
          {" "}
          <DatePicker
          SearchButton={() => {
            occasionList(startDate,endDate);
            // setPage(1)
          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />
      
          <button
            className={
              "border border-slate-200 px-6 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 text-base font-semibold"
            }
            onClick={(e) => navigate("/add-occasion")}
          >
            Add Occasion
          </button>
        </div>
  {loading ? (
        <LoadingComp />
      ) : <> { data.length? <><SpecialDays
          data={data}
         setModalContent={setModalContent}
        setOpenModal={setOpenModal}
        />   <Pagination
        maxPages={Math.ceil(count / 20)}
        dataCount={count}
        hitPage={pageHandler}
        datalength={data?.length}
        pageNum={ page }
      /></>
        
        :<DataNotFound/>}</>  }
      </div>
    </div>
  );
};

export default ScheduleCalender;
