import React, { useEffect, useState } from "react";
import ExcelDownload from "../Components/ExcelDownload";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import LoadingComp from "../Components/TailwindComponent/LoadingComp";
import Pagination from "../Components/TailwindComponent/Pagination";
import PostTab from "../Components/TailwindComponent/PostTab";

const LeastLoginUsers = () => {
  const [tabType, setTabType] = useState("This Week");
  const [loading, setLoading] = useState(false);
  const [leastLoginUser, setLeastLoginUser] = useState([]);
  const [page, setPage] = useState(1);
  const [countWeekly, setCountWeekly] = useState("");
  const [countMonthly, setCountMonthly] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customData, setCustomData] = useState([]);
  const [customCount, setCostomCount] = useState("");
  useEffect(() => {
    LeastLoginUser();
  }, [page]);
  const LeastLoginUser = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      content:20,
      page:page
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1_1/GetOnepostLeastLoginUsers",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setLeastLoginUser(result.data);
          setCountWeekly(result.data.CurrentWeekTotal)
          setCountMonthly(result.data.CurrentMonthTotal)
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const DateRangeData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      content:20,
      page:page,
      "StartDate": startDate,
      "EndDate": endDate
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1_1/GetOnepostLeastLoginUsers",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCustomData(result.data.DateRange);
          setCostomCount(result.data.DateRangeTotal);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const filteredleastLoginUser = () => {
    if (tabType === "Today") {
      return leastLoginUser.CurrentDate;
    }
    if (tabType === "This Week") {
      return leastLoginUser.CurrentWeek;
    }
    if (tabType === "This Month") {
      return leastLoginUser.CurrentMonth;
    }
    if(tabType==="Custom"){
      return customData
    }
  };
  const MaxPage = () => {
    var maxPage;
    if (tabType === "This Week") {
       maxPage=Math.ceil(countWeekly / 20)
    }
    if (tabType === "This Month") {
       maxPage=Math.ceil(countMonthly / 20)
    }
    if (tabType === "Custom") {
      maxPage=Math.ceil(customCount / 20)
   }
    return maxPage
  };
  const DataCount = () => {
    var dataCount;
    if (tabType === "This Week") {
       dataCount=countWeekly
    }
    if (tabType === "This Month") {
       dataCount=countMonthly 
    }
    if (tabType === "Custom") {
      dataCount=customCount 
   }
    return dataCount
  };
  const pageHandler = (value) => {
    setPage(value);
  };
  const tabs = [
    { name: "This Week", current: false },
    { name: "This Month", current: false },
    { name: "Custom", current: false },
  ];
  return (
    <div className=" border border-slate-100 p-2 sm:p-6 mb-8 rounded">
      <div className="flex justify-between items-center text-lg font-bold ">Least Login users <ExcelDownload OPActivity={true} csvData={filteredleastLoginUser()} fileName={`${tabType} Least Login users`} Excel={`${tabType} Excel Download`}/></div>
      <PostTab twoTabs={true} setTabType={setTabType} tabs={tabs}  tabType={tabType} />
      {tabType==="Custom" ?   <DatePicker
          SearchButton={() => {
            DateRangeData();
            setPage(1)
          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />:<></>}
      {loading ? (
        <LoadingComp />
      ) : filteredleastLoginUser()?.length ? (
        <><List data={filteredleastLoginUser()} type={"MostLoginUsers"} />      <Pagination
        maxPages={MaxPage()}
        dataCount={DataCount()}
        hitPage={pageHandler}
        datalength={filteredleastLoginUser()?.length}
        pageNum={ page }
      /></>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
};
export default LeastLoginUsers;
