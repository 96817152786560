import React from "react";
import Button from "./Button";
import Input from "./Input";

const DatePicker = (props) => {

   return (
    <>

        <div className="bg-white py-5 flex flex-col justify-center sm:rounded-lg ">
          <div className="flex flex-col items-center md:flex-row ">
            <div className="flex flex-col items-start md:items-center md:flex-row">
              <div className="mr-12">  <Input type = "date" dateLabel = "startDate" label = "From" value={props.StartValue} onChange={props.StartonChange} /></div>
              <div className="mr-8">  <Input type = "date" dateLabel = "endDate" label = "To" value={props.EndValue} onChange={props.EndonChange}  /></div>
             <div className="mt-4 m-auto sm:mt-2"> <Button type = {'smallButton'} buttonText = {'Search'}  onClick={props.SearchButton}/></div>
            </div>
          </div>
        </div>
    
    </>
  );
};

export default DatePicker;
