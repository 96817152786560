import React, { useState, useEffect } from 'react'
import Header from '../Components/TailwindComponent/Header'
import DatePicker from "../Components/TailwindComponent/DatePicker";
import Pagination from '../Components/TailwindComponent/Pagination';
import DataNotFound from '../Components/TailwindComponent/DataNotFound';
import ExcelDownload from "../Components/ExcelDownload";
import List from '../Components/TailwindComponent/List';
import { useNavigate } from 'react-router-dom';
import Searchbar from '../Components/TailwindComponent/Searchbar';
import LoadingComp from '../Components/TailwindComponent/LoadingComp';
import Modal from '../Components/TailwindComponent/Modal';
import SuccessMessage from '../Components/TailwindComponent/SuccessMessage';


const ArticleTable = () => {

    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [maxPage, setMaxPage] = useState(1);
    const [searchPage, setSearchPage] = useState(1);
    const [count, setCount] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [articleUUID, setArticleUUID] = useState("");

    useEffect(() => {
        var date = new Date();
        date.setDate(date.getDate() - 30);

        let startDateFormat =
            date.getDate().toString().length === 1
                ? "0" + date.getDate()
                : date.getDate();

        const monthFormat =
            date.getMonth() + 1 > 9
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1);

        var finalDate =
            date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

        setStartDate(finalDate);

        var todayDate = new Date();
        todayDate.setDate(todayDate.getDate());

        let endDateFormat =
            todayDate.getDate().toString().length === 1
                ? "0" + todayDate.getDate()
                : todayDate.getDate();

        const todayMonthFormat =
            todayDate.getMonth() + 1 > 9
                ? todayDate.getMonth() + 1
                : "0" + (todayDate.getMonth() + 1);

        var finalToday =
            todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
        setEndDate(finalToday);
    }, []);

    useEffect(() => {
        fetchArticleListFunc()
    }, [searchPage, startDate, endDate,searchValue])

    const fetchArticleListFunc = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("page", searchPage);
        formdata.append("StartDate", startDate);
        formdata.append("EndDate", endDate);
        formdata.append("SearchTerm", searchValue);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1_1/GetArticalDataBylist", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code == 200 && result.status === "Success") {
                    setData(result.data);
                    setCount(result.count);
                    setMaxPage(Math.ceil(result.count / 20));
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false));
    }

    const pageHandler = (value) => {
        setSearchPage(value);
    };

    const messageCloseFunc = () => {
        setTimeout(() => {
          setMessageOpen(false);
        }, 2000);
      };

    const deleteFunc = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

        var formdata = new FormData();
        formdata.append("ArticleUUID", articleUUID);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1_1/UpdateArticle", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status_code == 200 && result.status === "Success") {
                    setMessageOpen(true);
                    setAlertMessage("Article Deleted Successfully")
                    messageCloseFunc();
                }
            })
            .then(() => fetchArticleListFunc())
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <Header />
            <div className="px-5 sm:px-8">
                <div className="sm:flex flex-col md:items-center items-start justify-between lg:flex-row m-auto md:w-5/6">
                    <DatePicker
                        SearchButton={() => {
                            setSearchValue("");
                            setSearchPage(1);
                            fetchArticleListFunc();
                        }}
                        StartValue={startDate}
                        EndValue={endDate}
                        StartonChange={(e) => setStartDate(e.target.value)}
                        EndonChange={(e) => setEndDate(e.target.value)}
                    />
                    <Searchbar
                        SearchButton={() => {
                            setSearchPage(1);
                            fetchArticleListFunc();
                        }}
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        onClick={()=>alert("Hello")}
                    />
                </div>
                <div>
                    <div className="border-b border-gray-200">
                        <div className='mt-4'>
                            {data.length > 0 ? (
                                <>
                                    <div className="flex items-center justify-end">
                                        <button
                                            type="submit"
                                            className="ml-3 mr-5 h-10 border border-slate-200 px-6 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 text-base font-semibold"
                                            onClick={() => {
                                                navigate("/newArticle")
                                                setLoading(true)
                                            }}>Add New Article</button>
                                        <ExcelDownload csvData={data} fileName="Artcles List" Excel={"Download Excel"} />
                                    </div>
                                    {loading ? (
                                        <LoadingComp />
                                    ) : (
                                        <>
                                            <SuccessMessage
                                                message={alertMessage}
                                                show={messageOpen}
                                                onClose={() => setMessageOpen(false)} />
                                            <Modal
                                                open={open}
                                                deleteFunc={deleteFunc}
                                                setOpen={setOpen}
                                                title="Delete Article"
                                                message="Are you sure you want to delete this article? All of your data will be permanently removed. This action cannot be undone."
                                                type={"ConfirmBox"}
                                            />
                                            <List
                                                data={data}
                                                type={"ArticleTable"}
                                                pageNum={searchPage}
                                                setArticleUUID={setArticleUUID}
                                                setOpen={setOpen}
                                                messageCloseFunc={messageCloseFunc}
                                                setAlertMessage={setAlertMessage}
                                                setMessageOpen={setMessageOpen}
                                            />
                                            {
                                                <Pagination
                                                    maxPages={maxPage}
                                                    dataCount={count}
                                                    datalength={data.length}
                                                    hitPage={pageHandler}
                                                    pageNum={searchPage}
                                                />
                                            }
                                        </>
                                    )}
                                </>
                            ) : (
                                <DataNotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ArticleTable