import React, {useState} from "react";

const Input = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropDownFocus, setDropDownFocus] = useState();
  const [flag, setFlag] = useState();
  const [searchItem, setSearchItem] = useState("India");
  const [countryNameFilter, setCountryNameFilter] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  let regEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  const onOptionClicked = (item, value, flag, countryDialCode, country) => {
    setSearchItem(value);
    setIsOpen(false);
    setFlag(flag);
    if (props.setCountry) {
      props.setCountry(value);
    }
    props.setCountryObject(item);
  };

  const DropdowonOptions = () => {
    switch (props.label || props.placeHolder) {
      case "Country":
        return (
          <>
            {props.displayData
              .filter((data) => {
                if (
                  searchItem === "" ||
                  searchItem === "India" ||
                  countryNameFilter
                ) {
                  return props.displayData;
                } else if (
                  data.name.toLowerCase().startsWith(searchItem.toLowerCase())
                ) {
                  return data.name;
                }
              })
              .map((item, index) => {
                return (
                  <>
                    <li
                      onClick={() =>
                        onOptionClicked(
                          item,
                          item.name,
                          `https://flagpedia.net/data/flags/normal/${item.code.toLowerCase()}.png`,
                          item.dial_code,
                          item.name,
                          item
                        )
                      }
                      className="bg-white m-4 cursor-pointer text-left list-none hover:text-gray"
                      key={item}
                    >
                      <img
                        className="h-18 w-32 mr-10 mt-5"
                        src={`https://flagpedia.net/data/flags/normal/${item.code.toLowerCase()}.png`}
                        alt="Country_Flag_Logo"
                      />
                      {item.name}
                    </li>
                  </>
                );
              })}
          </>
        );
      case "Select Country":
        return (
          <>
            <option value="" disabled>
              Select country
            </option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <>
                    <option
                      key={item.id}
                      selected={
                        props.defaultCountry === item.country ? true : false
                      }
                      value={item.country}
                    >
                      {item.country}
                    </option>
                  </>
                );
              })}
          </>
        );
      case "Select State":
        return (
          <>
            <option value="" disabled>
              Select state
            </option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  item.country_name === props.selectedCountry && (
                    <option
                      key={item.id}
                      selected={props.defaultState === item.name ? true : false}
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  )
                );
              })}
          </>
        );
      case "Select City":
        return (
          <>
            <option value="" disabled>
              Select city
            </option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <option
                    key={item.id}
                    selected={props.defaultCity === item.City ? true : false}
                    value={item.City}
                  >
                    {item.City}
                  </option>
                );
              })}
          </>
        );
      default:
        break;
    }
  };

  const inputType = (type) => {
    switch (type) {
      case "date":
        return (
          <div className="flex items-center">
            <label
              //   htmlFor="startDate"
              htmlFor={props.dateLabel}
              className="w-10 block text-sm font-medium text-gray-700 pt-1"
            >
              {/* To */}
              {props.label}
            </label>
            <div className="mt-2">
              <input
                type="date"
                // id="endDate"
                id={props.dateLabel}
                // name="endDate"
                value={props.value}
                onChange={props.onChange}
                name={props.dateLabel}
                min={props.valid}
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
              />
            </div>
          </div>
        );
        case "Text":
          return (
            <div className="flex items-center">
              <label
                //   htmlFor="startDate"
                htmlFor={props.dateLabel}
                className="w-10 block text-sm font-medium text-gray-700 pt-1"
              >
                {/* To */}
                {props.label}
              </label>
              <div className="mt-2">
                <input
                  type={props.Inputtype||"text"}
                  placeholder={props.placeholder}
                  id={props.dateLabel}
                  // name="endDate"
                  value={props.value}
                  onChange={props.onChange}
                  name={props.dateLabel}
                  min={props.valid}
                  pattern={props.pattern}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                />
              </div>
            </div>
          );
          case "BlockText":
            return (
              <div className="w-full">
                <label
                  htmlFor={props.label}
                  className="block text-sm font-medium text-gray-700"
                >
                  {props.label}
                </label>
                <div className="mt-1">
                  <input
                    disabled={props.disabled}
                    type={props.type}
                    name={props.label}
                    id={props.label}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    className={`block w-full border p-2  outline-none rounded-md  shadow-sm ${
                      props.error
                        ? "border-red-600  focus:border-orange-500"
                        : "border-gray-300 focus:border-indigo-500"
                    } focus:ring-indigo-500 sm:text-sm ${
                      props.disabled && "bg-gray-200"
                    }`}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    readOnly={props.readOnly}
                  />
                </div>
                <span className="text-red-600 text-sm">{props.error}</span>
              </div>
            );
            /* case "dropdown":
              return (
                <div>
                  <label
                    htmlFor={props.label}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {props.label}
                  </label>
                  <select
                    id={props.label}
                    className={`mt-1 block w-full border  outline-none text-sm rounded-md border-gray-300 p-2  ${
                      props.error
                        ? "border-red-500 focus:ring-orange-500 bg-red-100"
                        : "focus:border-indigo-500 focus:ring-indigo-500 bg-white"
                    }`}
                    value={props.value}
                    onChange={props.onChange}
                  >
                    <option
                      className="text-sm"
                      value=""
                      selected={props.selected}
                      disabled={true}
                    >
                      {props.default}
                    </option>
                    {dropdownOption(props.optionData)}
                  </select>
                  <span className="text-red-600 text-sm">{props.error}</span>
                </div>
              ); */
      case "search":
        return (
          <>
            <div className="relative rounded-md shadow-sm ">
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search..."
                className=" block w-full rounded-md border-gray-300 pl-4 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={props.value}
                onChange={props.onChange}
                onInput={props.onInput}
                required
              />

            </div>
            <div className="text-red-600 pt-1 pl-1">{props.error}</div>
          </>
        );
      case "file":
          return (
            <>
              <div className="flex flex-col mt-2">
                <label >{props.label}</label>
                <label
                  className={"border-solid border text-lg mx-0 my-2 p-2 hover:border-indigo-300" + " " + "cursor-pointer flex justify-between"}
                  vlaue={props.value}
                  for="upload"
                >
                  <input
                    type="file"
                    id="upload"
                    accept=".png, .jpg, .jpeg"
                    onChange={props.onChange}
                    onKeyPress={props.onKeyPress}
                  />
                  {props.icon}
                </label>
              </div>
            </>
          );
      case "DropdownLoc":
            return (
              <div>
                <label
                 htmlFor={props.label} 
                className="block text-sm font-medium text-gray-700">{props.label}</label>
                <select
                id={props.label}
                  onChange={props.onChange}
                  className={`mt-1 block w-full border  outline-none text-sm rounded-md border-gray-300 py-2 pl-3 pr-10 text-base ${
                    props.error
                      ? "border-red-500 focus:ring-orange-500 bg-red-100"
                      : "focus:border-indigo-500 focus:ring-indigo-500 bg-white"
                  }`}
                  onInput={props.onInput}
                  value={props.value}
                >
                  {DropdowonOptions()}
                </select>
              </div>
            );
      case "singleLine":
              return (
                <div className=" items-center">
                  <label
                    //   htmlFor="startDate"
                    htmlFor={props.dateLabel}
                    className="w-full block text-sm font-medium text-gray-700 pt-1"
                  >
              
                    {props.label}
                  </label>
                  <div className="my-2">
                    <input
                      type={props.inputType}
                      required
                      placeholder={props.placeholder}
                      id={props.dateLabel}
                      // name="endDate"
                      value={props.value}
                      onChange={props.onChange}
                      name={props.dateLabel}
                      min={props.valid}
                      onKeyDown={props.onKeyDown}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <span className={"block text-sm font-medium text-red-700"}>
                    {props.error}
                  </span>
                </div>
              );
              case "textarea":
                return (
                  <div className=" items-center">
                    <label
                      //   htmlFor="startDate"
                      htmlFor={props.dateLabel}
                      className="w-full block text-sm font-medium text-gray-700 pt-1"
                    >
                
                      {props.label}
                    </label>
                    <div className="my-2">
                      <textarea 
                        type={props.inputType}
                        required
                        placeholder={props.placeholder}
                        id={props.dateLabel}
                        // name="endDate"
                        rows="4" 
                        cols="50"
                        value={props.value}
                        onChange={props.onChange}
                        name={props.dateLabel}
                        min={props.valid}
                        onKeyDown={props.onKeyDown}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <span className={"block text-sm font-medium text-red-700"}>
                      {props.error}
                    </span>
                  </div>
                );
              case "files":
                return (
                  <div className="mt-5">
                    <label
                      htmlFor={props.label}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {props.label}
                    </label>
                    <label
                      htmlFor="file-upload"
                      className={`block w-full py-4 mt-2 border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm 
                      ${
                        props.error
                          ? "border-red-500 focus:ring-orange-500"
                          : "focus:border-indigo-500 focus:ring-indigo-500"
                      }`}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm justify-center text-gray-600">
                          <label
                            htmlFor={props.id||"file-upload"}
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span className="text-center">Upload a file</span>
                            <input
                              id={props.id||"file-upload"}
                              multiple={props.multiple}
                              onChange={props.onChange}
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, JPEG, WEBP</p>
                      </div>
                    </label>
                    <span className={"block text-sm font-medium text-red-700"}>
                      {props.error}
                    </span>
                  </div>
                );
      default:
        break;
    }
  };
  return <div>{inputType(props.type)}</div>;
};

export default Input;
