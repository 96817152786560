import React, { useState, useEffect, useRef } from 'react';
import Header from '../Components/TailwindComponent/Header'
import TinyComponents from '../Components/TailwindComponent/TinyComponents';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../Components/TailwindComponent/Button';
import SuccessMessage from '../Components/TailwindComponent/SuccessMessage';
import LoadingComp from '../Components/TailwindComponent/LoadingComp';

const UpdateArticle = () => {

    const moreImages = useRef();
    const [dropDownUrl, setDropDownUrl] = useState("");
    const [imageTypeAlert, setImageTypeAlert] = useState({ coverImageAlert: false, articleImageAlert: false, thumbnailImageAlert: false, multipleImageAlert: false });
    const [category, setCategory] = useState([]);
    const [type, setType] = useState([]);
    const [subCategory, setSubCategories] = useState([]);
    const [source, setSource] = useState([]);
    const [addCategory, setNewCategories] = useState({ category: false, type: false, subCategory: false, articleImage: false })
    const [copied, setCopied] = useState(setTimeout(() => { setCopied("") }, 3000));
    const [updation, setUpdation] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [messageOpen, setMessageOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [showImages, setShowImages] = useState({
        multipleImage: [],
        coverImage: "",
        articleImage: "",
        thumbnail: ""
    })

    const { id } = useParams();
    const navigate = useNavigate();

    const [rightFormData, setRightForm] = useState({
        live: false,
        isStatic: false,
        titleDesc: "",
        shortDesc: "",
        contentDesc: "",
        coverImage: "",
        activeImage: "",
        multipleImage: [],
        thumbnail: "",
        keywords: "",
        hashtags: "",
        category: "",
        type: "",
        subCategory: "",
        articleSource: "",
        author: ""
    })
    const messageCloseFunc = () => {
        setTimeout(() => {
            setMessageOpen(false);
        }, 2000);
    };
    const imagesHandle = (e, imgName) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
        var formdata = new FormData();
        formdata.append("MultipalImage", e.target.files[0]);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch("https://api.sasone.in/apiadmin/v1/MultipaliMages", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (imgName === "coverImg") {
                    if (/\.(jpg|jpeg|png|webp|avif|gif|svg|avi|mpg|mpeg|mov|wmv|ogg|mp4)$/.test(e.target.files[0].name)) {
                        setShowImages({ ...showImages, coverImage: result.data })
                        setRightForm({ ...rightFormData, coverImage: e.target.files[0] })
                        setImageTypeAlert({ ...imageTypeAlert, coverImageAlert: false });
                    } else {
                        console.log(/\.(jpg|jpeg|png|webp|avif|gif|svg|avi|mpg|mpeg|mov|wmv|ogg|mp4)$/.test(e.target.files[0]))
                        setImageTypeAlert({ ...imageTypeAlert, coverImageAlert: true });
                        setShowImages({ ...showImages, coverImage: "" })
                        setRightForm({ ...rightFormData, coverImage: "" })
                    }
                }
                else if (imgName === "articleImg") {
                    if (/\.(jpg|jpeg|png|webp|avif|gif|svg|avi|mpg|mpeg|mov|wmv|ogg|mp4)$/.test(e.target.files[0].name)) {
                        setShowImages({ ...showImages, articleImage: result.data })
                        setRightForm({ ...rightFormData, activeImage: e.target.files[0] })
                        setImageTypeAlert({ ...imageTypeAlert, articleImageAlert: false });
                    } else {
                        setImageTypeAlert({ ...imageTypeAlert, articleImageAlert: true });
                        setShowImages({ ...showImages, articleImage: "" })
                        setRightForm({ ...rightFormData, activeImage: "" })
                    }
                }
                else {
                    if (/\.(jpg|jpeg|png|webp|avif|gif|svg|avi|mpg|mpeg|mov|wmv|ogg|mp4)$/.test(e.target.files[0].name)) {
                        setShowImages({ ...showImages, thumbnail: result.data })
                        setRightForm({ ...rightFormData, thumbnail: e.target.files[0] })
                        setImageTypeAlert({ ...imageTypeAlert, thumbnailImageAlert: false });
                    } else {
                        setImageTypeAlert({ ...imageTypeAlert, thumbnailImageAlert: true });
                        setShowImages({ ...showImages, thumbnail: "" })
                        setRightForm({ ...rightFormData, thumbnail: "" })
                    }
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false));
    }
    const uploadMultipleImage = (event) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
        const files = event.target.files;
        var formdata = new FormData();
        const blank = [];
        for (let i = 0; i < files.length; i++) {
            if (/\.(jpg|jpeg|png|webp|avif|gif|svg|avi|mpg|mpeg|mov|wmv|ogg|mp4)$/.test(files[i].name)) {
                blank.push(files[i]);
                formdata.append("MultipalImage", files[i]);
                setImageTypeAlert({ ...imageTypeAlert, multipleImageAlert: false });
            } else {
                setImageTypeAlert({ ...imageTypeAlert, multipleImageAlert: true });
            }
        }
        setRightForm({ ...rightFormData, multipleImage: blank })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/MultipaliMages", requestOptions)
            .then(response => response.json())
            .then(result => {
                for (let i = 0; i < result.data.length; i++) {
                    setUpdation(!updation);
                    showImages.multipleImage.push(result.data[i]);
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false));
    }
    const deleteMultiple = (id) => {
        if (window.confirm("Are you sure you want to remove this image")) {
            showImages.multipleImage.splice(id, 1);
            setShowImages({ ...showImages, multipleImage: showImages.multipleImage });
            const dataImage = [];
            for (let i = 0; i < rightFormData.multipleImage.length; i++) {
                if (i != id) {
                    dataImage.push(rightFormData.multipleImage[i]);
                }
                setRightForm({ ...rightFormData, multipleImage: dataImage });
            }
        }
    }
    const handleForm = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ArticleUUID": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://api.sasone.in/apiadmin/v1_1/GetArticleCategoryDataByUserId", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                const image = result.scheduled.Articleimages.split('"').join().split(",");
                setRightForm({
                    live: ((result.scheduled.IsPublished === "t" || result.scheduled.IsPublished == "1") ? true : false),
                    isStatic: ((result.scheduled.Static === "t" || result.scheduled.Static == "1") ? true : false),
                    titleDesc: result.scheduled.Title,
                    shortDesc: result.scheduled.ShortDescription,
                    contentDesc: result.scheduled.Content,
                    coverImage: result.scheduled.Image,
                    activeImage: result.scheduled.BannerImage,
                    multipleImage: image,
                    thumbnail: result.scheduled.MobileThumb,
                    keywords: result.scheduled.Keywords,
                    hashtags: result.scheduled.Tags,
                    category: result.scheduled.Categories,
                    type: result.scheduled.Type,
                    subCategory: result.scheduled.SubCategory,
                    articleSource: result.scheduled.ArticleSource,
                    author: result.scheduled.Author,
                });
                setShowImages({
                    multipleImage: image,
                    coverImage: result.scheduled.Image,
                    articleImage: result.scheduled.BannerImage,
                    thumbnail: result.scheduled.MobileThumb
                })
            })
            .catch(error => console.log('error', error));
        var categoryData = new FormData();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: categoryData,
            redirect: 'follow'
        };

        fetch("https://api.sasone.in/apiadmin/v1/ArticleCategorylist", requestOptions)
            .then(response => response.json())
            .then(result => setCategory(result.data))
            .catch(error => console.log('error', error));

        fetch("https://api.sasone.in/apiadmin/v1/ArticleType", requestOptions)
            .then(response => response.json())
            .then(result => setType(result.data))
            .catch(error => console.log('error', error));

        fetch("https://api.sasone.in/apiadmin/v1/ArticleSubCategory", requestOptions)
            .then(response => response.json())
            .then(result => setSubCategories(result.data))
            .catch(error => console.log('error', error));

        fetch("https://api.sasone.in/apiadmin/v1/ArticleSource", requestOptions)
            .then(response => response.json())
            .then(result => setSource(result.data))
            .catch(error => console.log('error', error));
    }

    const addNewCategory = (categoryType) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
        var formdata = new FormData();
        if (categoryType === "Category") {
            if (rightFormData.category !== "newCategory" && rightFormData.category !== "") {
                formdata.append("Category", rightFormData.category);
                setDropDownUrl("https://api.sasone.in/apiadmin/v1/Category");
            }
            else {
                setNewCategories({ ...addCategory, category: false })
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        if (dropDownUrl !== "") {
            fetch(dropDownUrl, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    console.log(result)
                    setRightForm({ ...rightFormData, category: "" })
                    setNewCategories({ category: false })
                    setMessageOpen(true);
                    setAlertMessage("Added Successfully")
                    messageCloseFunc();
                })
                .then(() => {
                    fetch("https://api.sasone.in/apiadmin/v1/ArticleCategorylist", requestOptions)
                        .then(response => response.json())
                        .then(result => setCategory(result.data))
                        .catch(error => console.log('error', error));
                })
                .catch(error => console.log('error', error))
                .finally(() => setLoading(false));
        }
    }

    const updateArticleFunc = () => {
        //console.log(rightFormData)
        if (window.confirm("Are you sure you want to update this article")) {
            setLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

            var formdata = new FormData();
            formdata.append("ArticleUUID", id);
            formdata.append("Title", rightFormData.titleDesc);
            formdata.append("ShortDescription", rightFormData.shortDesc);
            formdata.append("Content", rightFormData.contentDesc);
            formdata.append("Author", rightFormData.author);
            formdata.append("Tags", rightFormData.hashtags);
            formdata.append("ArticleSource", rightFormData.articleSource);
            formdata.append("Categories", rightFormData.category);
            formdata.append("IsPublished", rightFormData.live ? 1 : 0);
            formdata.append("Static", rightFormData.isStatic ? 1 : 0);
            formdata.append("Keywords", rightFormData.keywords);
            formdata.append("Type", rightFormData.type);
            formdata.append("SubCategory", rightFormData.subCategory);
            formdata.append("ArticleText", rightFormData.shortDesc);
            formdata.append("IsDeleted", 0);
            if (typeof (rightFormData.coverImage) != typeof ("string")) {
                formdata.append("Image", rightFormData.coverImage);
            }
            if (typeof (rightFormData.activeImage) != typeof ("string")) {
                formdata.append("BannerImage", rightFormData.activeImage);
            }
            if (typeof (rightFormData.thumbnail) != typeof ("string")) {
                formdata.append("MobileThumb", rightFormData.thumbnail);
            }

            for (let i = 0; i < rightFormData.multipleImage.length; i++) {
                if (typeof (rightFormData.multipleImage[i]) != typeof ("string")) {
                    formdata.append("MultipleImage", rightFormData.multipleImage[i]);
                }
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://api.sasone.in/apiadmin/v1_1/UpdateArticle", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status_code == 200 && result.status === "Success") {
                        setMessageOpen(true);
                        setAlertMessage("Article Updated Successfully")
                        messageCloseFunc();
                    }
                    return result
                })
                .then((result) => {
                    if (result.status_code == 200 && result.status === "Success") {
                        navigate("/articles")
                    }
                })
                .catch(error => console.log('error', error))
                .finally(()=>setLoading(false));
        }
    }

    useEffect(() => {
        handleForm();
    }, []);

    useEffect(() => {
        if (rightFormData.category === "+addNewDropDown") {
            setNewCategories({ ...addCategory, category: true })
            setRightForm({ ...rightFormData, category: "" })
        }
        else if (rightFormData.type === "+addNewDropDown") {
            setNewCategories({ ...addCategory, type: true })
            setRightForm({ ...rightFormData, type: "" })
        }
        else if (rightFormData.subCategory === "+addNewDropDown") {
            setNewCategories({ ...addCategory, subCategory: true })
            setRightForm({ ...rightFormData, subCategory: "" })
        }
        else if (rightFormData.articleSource === "+addNewDropDown") {
            setNewCategories({ ...addCategory, articleSource: true })
            setRightForm({ ...rightFormData, articleSource: "" })
        }
    }, [rightFormData])

    return (
        <>
            <Header />
            <SuccessMessage
                message={alertMessage}
                show={messageOpen}
                onClose={() => setMessageOpen(false)} />
            {loading && <LoadingComp />}
            <div className="flex h-screen">
                {/* Content area */}
                <div className="flex flex-1 flex-col">

                    {/* Main content */}
                    <div className="sm:flex sm:flex-1 items-stretch">
                        <main className="sm:flex-1 overflow-y-auto">
                            {/* Primary column */}
                            <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last">
                                <div className="flex items-center place-content-between px-10">
                                    <h1 className="text-left m-5 text-lg font-bold">Update Article</h1>
                                    <div className="flex items-start">
                                        <div className="mr-3 text-sm">
                                            <label htmlFor="isLive" className="font-medium text-gray-700">Live: </label>
                                        </div>
                                        <div className="flex h-5 items-center">
                                            <input id="isLive" name="live" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 mr-10 focus:ring-indigo-500"
                                                checked={rightFormData.live}
                                                onClick={() => setRightForm({ ...rightFormData, live: !rightFormData.live })} />
                                        </div>
                                        <div className="mr-3 text-sm">
                                            <label htmlFor="isStatic" className="font-medium text-gray-700">Static: </label>
                                        </div>
                                        <div className="flex h-5 items-center">
                                            <input id="isStatic" name="static" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                checked={rightFormData.isStatic}
                                                onClick={() => setRightForm({ ...rightFormData, isStatic: !rightFormData.isStatic })} />
                                        </div>
                                    </div>
                                </div>
                                {/* Your content */}
                                <div className="textEditor px-10 mt-6">
                                    <div>
                                        <label
                                            htmlFor="title"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Title
                                        </label>
                                        <div className="mt-1">
                                            <textarea
                                                id="title"
                                                name="title"
                                                rows={3}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Title"
                                                maxLength={255}
                                                defaultValue={rightFormData.titleDesc}
                                                onChange={(e) => setRightForm({ ...rightFormData, titleDesc: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="mt-2 text-sm text-gray-500">(Maximum 255 Characters)</p>
                                            <p className="mt-2 text-sm text-gray-500">{"character count : " + rightFormData.titleDesc.length}</p>
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <label
                                            htmlFor="short-description"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Short Description
                                        </label>
                                        <div className="mt-1">
                                            <textarea
                                                id="short-description"
                                                name="short-description"
                                                rows={3}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Short Description"
                                                maxLength={255}
                                                defaultValue={rightFormData.shortDesc}
                                                onChange={(e) => setRightForm({ ...rightFormData, shortDesc: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="mt-2 text-sm text-gray-500">(Maximum 255 Characters)</p>
                                            <p className="mt-2 text-sm text-gray-500">{"character count : " + rightFormData.shortDesc.length}</p>
                                        </div>
                                    </div>
                                    <TinyComponents
                                        updateValue={rightFormData.contentDesc}
                                        content={"Content"}
                                        setDataFunc={(newValue) =>
                                            setRightForm({ ...rightFormData, contentDesc: newValue })} />
                                </div>
                            </section>
                        </main>

                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="w-96 overflow-y-auto border-l border-gray-200 bg-white lg:block">
                            {/* Your content */}
                            <h2 className='text-center mt-5 text-lg font-bold'>Other Details</h2>
                            <div className="md:col-span-2 md:mt-0">
                                <div>
                                    <div className="flex items-center place-content-between px-10 sm:px-0 overflow-hidden shadow sm:rounded-md">
                                        <div className="bg-white px-2 py-2 sm:p-6">
                                            <div>
                                                <div className="mt-4">
                                                    <label htmlFor="cover-image" className="block text-sm font-medium text-gray-700">
                                                        Cover Image:
                                                    </label>
                                                    <input type={"file"} id="cover-image"
                                                        accept="image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" className='border-solid border text-md mx-0 my-2 p-2 hover:border-indigo-300 cursor-pointer flex justify-between'
                                                        onChange={(e) => imagesHandle(e, "coverImg")} />
                                                    {(imageTypeAlert.coverImageAlert) &&
                                                        <p className="text-red-500 text-sm">Please select only image and video</p>
                                                    }
                                                    {(showImages.coverImage.length > 0) &&
                                                        <div className='flex flex-row flex-wrap justify-start gap-4 mt-2'>
                                                            <div className='w-14 h-12 rounded'>
                                                                <div className="ml-3 flex h-7 items-start transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">
                                                                    {(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(showImages.coverImage)) ?
                                                                        <img className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={showImages.coverImage} alt="cover image"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(showImages.coverImage);
                                                                                setCopied("coverImage")
                                                                            }} /> :
                                                                        <video className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={showImages.coverImage} alt="cover image"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(showImages.coverImage);
                                                                                setCopied("coverImage")
                                                                            }} />}
                                                                    <button
                                                                        type="button"
                                                                        className="-m-2 bg-gray-200 text-gray-400  rounded-full hover:text-gray-500"
                                                                        onClick={() => {
                                                                            if (window.confirm("Are you sure you want to remove this image")) {
                                                                                setShowImages({ ...showImages, coverImage: "" });
                                                                                setRightForm({ ...rightFormData, coverImage: "" })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className="sr-only">Close panel</span>
                                                                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                                {(copied === "coverImage") ? <div className="bg-green-300 border border-green-600 opacity-60 rounded-lg text-[10px] mt-2 ml-3 text-center">Copied</div> : ""}
                                                            </div>
                                                        </div>}
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="active-image" className="block text-sm font-medium text-gray-700">
                                                        Article Image:
                                                    </label>
                                                    <input type={"file"} id="active-image"
                                                        accept="image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" className='border-solid border text-md mx-0 my-2 p-2 hover:border-indigo-300 cursor-pointer flex justify-between'
                                                        onChange={(e) => imagesHandle(e, "articleImg")} />{(imageTypeAlert.articleImageAlert) &&
                                                            <p className="text-red-500 text-sm">Please select only image and video</p>
                                                    }
                                                    {(showImages.articleImage.length > 0) &&
                                                        <div className='flex flex-row flex-wrap justify-start gap-4 mt-2'>
                                                            <div className='w-14 h-12 rounded'>
                                                                <div className="ml-3 flex h-7 items-start transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">
                                                                    {(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(showImages.articleImage)) ?
                                                                        <img className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={showImages.articleImage} alt="article image"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(showImages.articleImage);
                                                                                setCopied("articleImage")
                                                                            }} /> :
                                                                        <video className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={showImages.articleImage} alt="article image"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(showImages.articleImage);
                                                                                setCopied("articleImage")
                                                                            }} />}
                                                                    <button
                                                                        type="button"
                                                                        className="-m-2 bg-gray-200 text-gray-400  rounded-full hover:text-gray-500"
                                                                        onClick={() => {
                                                                            if (window.confirm("Are you sure you want to remove this image")) {
                                                                                setShowImages({ ...showImages, articleImage: "" });
                                                                                setRightForm({ ...rightFormData, activeImage: "" })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className="sr-only">Close panel</span>
                                                                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                                {(copied === "articleImage") ? <div className="bg-green-300 border border-green-600 opacity-60 rounded-lg text-[10px] mt-2 ml-3 text-center">Copied</div> : ""}
                                                            </div>
                                                        </div>}
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="multiple-image" className="block text-sm font-medium text-gray-700">
                                                        Multiple Image:
                                                    </label>
                                                    <input type={"file"} id="Multiple Image"
                                                        accept="image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" className='border-solid border text-md mx-0 my-2 p-2 hover:border-indigo-300 cursor-pointer flex justify-between'
                                                        onChange={uploadMultipleImage} multiple />
                                                    {(imageTypeAlert.multipleImageAlert) &&
                                                        <p className="text-red-500 text-sm">Please select only image and video</p>
                                                    }
                                                    {(showImages.multipleImage.length > 0) &&
                                                        <div className='flex flex-row flex-wrap justify-start gap-4 mt-2'>{
                                                            showImages.multipleImage.map((img, imgIdx) => (img !== "") && (

                                                                <div className='w-14 h-12 rounded' key={imgIdx}>
                                                                    <div className="ml-3 flex h-7 items-start transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">
                                                                        {(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(img)) ?
                                                                            <img className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={img} alt={"img" + imgIdx}
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(img);
                                                                                    setCopied(imgIdx);
                                                                                }} /> :
                                                                            <video className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={img} alt={"img" + imgIdx}
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(img);
                                                                                    setCopied(imgIdx);
                                                                                }} />}
                                                                        <button
                                                                            type="button"
                                                                            className="-m-2 bg-gray-200 text-gray-400  rounded-full hover:text-gray-500"
                                                                            onClick={() => { deleteMultiple(imgIdx) }}
                                                                        >
                                                                            <span className="sr-only">Close panel</span>
                                                                            <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                                                        </button>
                                                                    </div>
                                                                    {(copied === imgIdx) ? <div className="bg-green-300 border border-green-600 opacity-60 rounded-lg text-[10px] mt-2 ml-3 text-center">Copied</div> : ""}
                                                                </div>
                                                            ))}<>
                                                                <input type={"file"} id="Multiple Image"
                                                                    accept="image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" ref={moreImages} onChange={uploadMultipleImage} className='hidden' multiple />
                                                                <button className="inline-flex mt-1 justify-center rounded-full border border-transparent opacity-70 bg-indigo-600 py-1.5 px-3 h-9 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => moreImages.current.click()}>+
                                                                </button></>
                                                        </div>}
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="mobile-thumbnail" className="block text-sm font-medium text-gray-700">
                                                        Mobile Thumbnail:
                                                    </label>
                                                    <input type={"file"} id="mobile-thumbnail"
                                                        accept="image/png, image/jpeg, image/jpg, .gif, image/.svg, video/*" className='border-solid border text-md mx-0 my-2 p-2 hover:border-indigo-300 cursor-pointer flex justify-between'
                                                        onChange={(e) => imagesHandle(e, "thumbnail")} required />
                                                    {(imageTypeAlert.thumbnailImageAlert) &&
                                                        <p className="text-red-500 text-sm">Please select only image and video</p>
                                                    }
                                                    {(showImages.thumbnail.length > 0) &&
                                                        <div className='flex flex-row flex-wrap justify-start gap-4 mt-2'>
                                                            <div className='w-14 h-12 rounded'>
                                                                <div className="ml-3 flex h-7 items-start transition duration-150 ease-in-out hover:-translate-y-1 hover:scale-110 duration-300">
                                                                    {(/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(showImages.thumbnail)) ?
                                                                        <img className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={showImages.thumbnail} alt="thumbnail image"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(showImages.thumbnail);
                                                                                setCopied("thumbnail")
                                                                            }} /> :
                                                                        <video className='border-2 border-gray-300 rounded w-14 h-12 cursor-pointer' src={showImages.thumbnail} alt="thumbnail image"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(showImages.thumbnail);
                                                                                setCopied("thumbnail")
                                                                            }} />}
                                                                    <button
                                                                        type="button"
                                                                        className="-m-2 bg-gray-200 text-gray-400  rounded-full hover:text-gray-500"
                                                                        onClick={() => {
                                                                            if (window.confirm("Are you sure you want to remove this image")) {
                                                                                setShowImages({ ...showImages, thumbnail: "" });
                                                                                setRightForm({ ...rightFormData, thumbnail: "" })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className="sr-only">Close panel</span>
                                                                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                                {(copied === "thumbnail") ? <div className="bg-green-300 border border-green-600 opacity-60 rounded-lg text-[10px] mt-2 ml-3 text-center">Copied</div> : ""}
                                                            </div>
                                                        </div>}
                                                </div>

                                                <div className="mt-4">
                                                    <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">
                                                        Keywords:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="keywords"
                                                        id="keywords"
                                                        autoComplete="keywords"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.keywords}
                                                        onChange={(e) => setRightForm({ ...rightFormData, keywords: e.target.value })} required />
                                                </div>
                                                <div className='mt-4'>
                                                    <label htmlFor="hastags" className="block text-sm font-medium text-gray-700">
                                                        Hashtags:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="hashtags"
                                                        id="hashtags"
                                                        autoComplete="given-name"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.hashtags}
                                                        onChange={(e) => setRightForm({ ...rightFormData, hashtags: e.target.value })} required />
                                                </div>
                                                {/* Category Drop Down Component =========================== */}
                                                <div className="mt-4">
                                                    <label htmlFor="Category" className="block text-sm font-medium text-gray-700">
                                                        Category: <sup className='text-red-500'>*</sup>
                                                    </label>
                                                    <select
                                                        id="category"
                                                        name="category"
                                                        autoComplete="category"
                                                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.category}
                                                        onChange={(e) => {
                                                            setNewCategories({ ...addCategory, category: false })
                                                            setRightForm({ ...rightFormData, category: e.target.value })
                                                        }} required >
                                                        <option value={""} defaultValue>Select Category</option>
                                                        {(category.length > 0) &&
                                                            category.map((data, i) => (
                                                                <option key={i} value={data.Category}>{data.Category}</option>
                                                            ))
                                                        }
                                                        <option className="bg-gray-400 text-white" value="+addNewDropDown">+ Add New</option>
                                                    </select>
                                                    {(addCategory.category) &&
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="newCategory"
                                                                id="newCategory"
                                                                autoComplete="newCategory"
                                                                className="mt-1 w-4/5 rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                value={rightFormData.category}
                                                                onChange={(e) => setRightForm({ ...rightFormData, category: e.target.value })} />
                                                            <button className="ml-2 px-3 py-1.5 rounded-md bg-gray-100 border border-gray-300 hover:bg-gray-200" onClick={() => addNewCategory("Category")}>+</button>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                                        Type:
                                                    </label>
                                                    <select
                                                        id="type"
                                                        name="type"
                                                        autoComplete="type"
                                                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.type}
                                                        onChange={(e) => {
                                                            setNewCategories({ ...addCategory, type: false })
                                                            setRightForm({ ...rightFormData, type: e.target.value })
                                                        }} >
                                                        <option value={""} defaultValue>Select Type</option>
                                                        {
                                                            type.map((data, i) => (
                                                                <option key={i} value={data.Type}>{data.Type}</option>
                                                            ))
                                                        }
                                                        <option className="bg-gray-400 text-white" value="+addNewDropDown">+ Add New</option>
                                                    </select>
                                                    {(addCategory.type) &&
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="type"
                                                                id="type"
                                                                autoComplete="type"
                                                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                value={rightFormData.type}
                                                                onChange={(e) => setRightForm({ ...rightFormData, type: e.target.value })} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="sub-category" className="block text-sm font-medium text-gray-700">
                                                        Sub Category:
                                                    </label>
                                                    <select
                                                        id="sub-category"
                                                        name="sub-category"
                                                        autoComplete="sub-category"
                                                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.subCategory}
                                                        onChange={(e) => {
                                                            setNewCategories({ ...addCategory, subCategory: false })
                                                            setRightForm({ ...rightFormData, subCategory: e.target.value })
                                                        }} >
                                                        <option value={""} defaultValue>Select Sub Category</option>
                                                        {
                                                            subCategory.map((data, i) => (
                                                                <option key={i} value={data.SubCategory}>{data.SubCategory}</option>
                                                            ))
                                                        }
                                                        <option className="bg-gray-400 text-white" value="+addNewDropDown">+ Add New</option>
                                                    </select>
                                                    {(addCategory.subCategory) &&
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="subCategory"
                                                                id="subCategory"
                                                                autoComplete="subCategory"
                                                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                value={rightFormData.subCategory}
                                                                onChange={(e) => setRightForm({ ...rightFormData, subCategory: e.target.value })} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="article-source" className="block text-sm font-medium text-gray-700">
                                                        Article Source:
                                                    </label>
                                                    <select
                                                        id="article-source"
                                                        name="article-source"
                                                        autoComplete="article-source"
                                                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.articleSource}
                                                        onChange={(e) => {
                                                            setNewCategories({ ...addCategory, articleSource: false })
                                                            setRightForm({ ...rightFormData, articleSource: e.target.value })
                                                        }} required >
                                                        <option value={""} defaultValue>Select Article Source</option>
                                                        {
                                                            source.map((data, i) => (
                                                                <option key={i} value={data.ArticleSource}>{data.ArticleSource}</option>
                                                            ))
                                                        }
                                                        <option className="bg-gray-400 text-white" value="+addNewDropDown">+ Add New</option>
                                                    </select>
                                                    {(addCategory.articleSource) &&
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="articleSource"
                                                                id="articleSource"
                                                                autoComplete="articleSource"
                                                                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                value={rightFormData.articleSource}
                                                                onChange={(e) => setRightForm({ ...rightFormData, articleSource: e.target.value })} />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="mt-4">
                                                    <label htmlFor="author" className="block text-sm font-medium text-gray-700">
                                                        Author:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="author"
                                                        id="author"
                                                        autoComplete="given-name"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        value={rightFormData.author}
                                                        onChange={(e) => setRightForm({ ...rightFormData, author: e.target.value })} required />
                                                </div>
                                                <div className="py-3 flex items-center justify-evenly">
                                                    <div>
                                                        <Button
                                                            onClick={updateArticleFunc}
                                                            type="smallButton"
                                                            buttonText="Update" />
                                                    </div>
                                                    <div>
                                                        <Button
                                                            onClick={() => navigate("/articles")}
                                                            type="whiteButton"
                                                            buttonText="Cancel" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div >
        </>
    )
}

export default UpdateArticle;