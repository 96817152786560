import React, { useEffect, useState } from "react";
import ExcelDownload from "../Components/ExcelDownload";
import DataNotFound from "../Components/TailwindComponent/DataNotFound";
import DatePicker from "../Components/TailwindComponent/DatePicker";
import List from "../Components/TailwindComponent/List";
import LoadingComp from "../Components/TailwindComponent/LoadingComp";
import Pagination from "../Components/TailwindComponent/Pagination";
import PostTab from "../Components/TailwindComponent/PostTab";

const LeastActiveUser = () => {
  const [tabType, setTabType] = useState("Today");
  const [loading, setLoading] = useState(false);
  const [leastActiveUsers, setLeastActiveUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState("");
  const [countWeekly, setCountWeekly] = useState("");
  const [countMonthly, setCountMonthly] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customData, setCustomData] = useState([]);
  const [customCount, setCostomCount] = useState("");
  const tabs = [
    { name: "Today", current: true },
    { name: "This Week", current: false },
    { name: "This Month", current: false },
    { name: "Custom", current: false },
  ];
  useEffect(() => {
    MostActiveUsers();
  }, [page]);
  const MostActiveUsers = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      content:20,
      page:page
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1_1/GetOnepostLeastActiveUsers",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setLeastActiveUsers(result.data);
          setCount(result.data.CurrentDateTotal);
          setCountWeekly(result.data.CurrentWeekTotal)
          setCountMonthly(result.data.CurrentMonthTotal)
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const DateRangeData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      content:20,
      page:page,
      "StartDate": startDate,
      "EndDate": endDate
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1_1/GetOnepostLeastActiveUsers",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCustomData(result.data.DateRange);
          setCostomCount(result.data.DateRangeTotal);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const filteredleastActiveUsers = () => {
    if (tabType === "Today") {
      return leastActiveUsers.CurrentDate;
    }
    if (tabType === "This Week") {
      return leastActiveUsers.CurrentWeek;
    }
    if (tabType === "This Month") {
      return leastActiveUsers.CurrentMonth;
    }
    if(tabType==="Custom"){
      return customData
    }
    
  };
  const pageHandler = (value) => {
    setPage(value);
};
const MaxPage = () => {
  var maxPage;
  if (tabType === "Today") {
    maxPage=Math.ceil(count / 20);
  }
  if (tabType === "This Week") {
     maxPage=Math.ceil(countWeekly / 20)
  }
  if (tabType === "This Month") {
     maxPage=Math.ceil(countMonthly / 20)
  }
  if (tabType === "Custom") {
    maxPage=Math.ceil(customCount / 20)
 }
  return maxPage
};
const DataCount = () => {
  var dataCount;
  if (tabType === "Today") {
    dataCount=count ;
  }
  if (tabType === "This Week") {
     dataCount=countWeekly
  }
  if (tabType === "This Month") {
     dataCount=countMonthly 
  }
  if (tabType === "Custom") {
    dataCount=customCount 
 }
  return dataCount
};
  return (
    <div className=" border border-slate-100 p-2 sm:p-6 mb-8 rounded">
       <div className="flex justify-between text-lg items-center font-bold ">Least Active users <ExcelDownload OPActivity={true} csvData={filteredleastActiveUsers()} fileName={`${tabType} Least Active users`} Excel={`${tabType} Excel Download`}/></div>
      <PostTab setTabType={setTabType} tabs={tabs} tabType={tabType} />
      {tabType==="Custom" ?   <DatePicker
          SearchButton={() => {
            DateRangeData();
            setPage(1)
          }}
          StartValue={startDate}
          EndValue={endDate}
          StartonChange={(e) => setStartDate(e.target.value)}
          EndonChange={(e) => setEndDate(e.target.value)}
        />:<></>}
      {loading ? (
        <LoadingComp />
      ) : filteredleastActiveUsers()?.length ? (
       <> <List data={filteredleastActiveUsers()} type={"MostActiveUsers"} />      <Pagination
       maxPages={MaxPage()}
       dataCount={DataCount()}
       hitPage={pageHandler}
       datalength={filteredleastActiveUsers()?.length}
       pageNum={ page }
     /></>
      ) : (
        <DataNotFound />
      )}
    </div>
  );
};

export default LeastActiveUser;
